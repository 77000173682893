var csview = require('./view.js');
var csmodel = require('./model.js');

// Main Application Controller
const Controller = function (googleconf) {
    // Create view and model
    this.view = new csview();
    this.model = new csmodel(10, googleconf.baseURL, googleconf.writeBucket);
    
    var url = googleconf.baseURL.format(googleconf.writeBucket);

    // Setup save form
    $("#codeform")[0].action = url;
    $("#googleid")[0].value = googleconf.googleid;
    $("#policy")[0].value = googleconf.policy;
    $("#signature")[0].value = googleconf.signature;

    // Needed so adapters can call functions with the proper context
    var proxy = function (fn, context) {
        return (function () {
            return fn.apply(context, arguments);
        });
    };

    // View adapter for the model
    var m2v = {
        stdout: proxy(this.view.consoleOutput, this.view),
        stddbg: proxy(function (text) {
            this.view.colorOutput(text, "blue");
        }, this),
        stderr: proxy(function (text) {
            this.view.colorOutput(text, "red");
        }, this),
        flush: proxy(this.view.consoleFlush, this.view),
        // asyncDialog: proxy(this.view.asyncDialog, this.view),
        // setFilename: proxy(this.view.setFilename, this.view),
        // showDownload: proxy(this.view.showDownload, this.view),
        // getEditState: proxy(this.view.getEditState, this.view),
        setCode: proxy(this.view.setCode, this.view),
        getCode: proxy(this.view.getCode, this.view),
        // getTabStop: proxy(this.view.getTabStop, this.view),
        replaceLeadingTabs: proxy(this.view.replaceLeadingTabs, this.view),
        prepareSubmit: proxy(this.view.prepareSubmit, this.view),
        setHash: proxy(this.view.setHash, this.view),
        // reset: proxy(this.view.reset, this.view),
        // resetControlButtons: proxy(this.view.resetControlButtons, this.view),
        addHighlight: proxy(this.view.addHighlight, this.view),
        removeHighlight: proxy(this.view.removeHighlight, this.view),
	setLineClickHandler: proxy(this.view.lineHandler, this.view),
	openDialog: proxy(this.view.dialog, this.view),
	setGutter: proxy(this.view.setGutter, this.view),
        clearView: proxy(this.view.clearView, this.view)
    };

    // Model adapter for the view
    var v2m = {
        run: proxy(this.model.run, this.model),
        // print: proxy(this.model.print, this.model),
        pause: proxy(this.model.pause, this.model),
        isRunning: proxy(this.model.isRunning, this.model),
        isBlocked: proxy(this.model.isBlocked, this.model),
        // stop: proxy(this.model.stop, this.model),
        save: proxy(this.model.save, this.model),
        loadRemote: proxy(this.model.loadRemote, this.model),
        // loadLocal: proxy(this.model.loadLocal, this.model),
        reset: proxy(this.model.reset, this.model)
    };

    this.view.configure(v2m);
    this.model.configure(m2v);

    this.model.start();
    this.view.start();
};

module.exports = Controller;
