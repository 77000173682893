// CodeMirror
const CodeMirror = require('../vendor/CodeMirror/lib/codemirror.js');

CodeMirror.registerHelper("fold", "python", function(cm, start) {
    // Find Python block, ignore blanks and comments
    var tabSize = cm.getOption("tabSize");
    var firstLine = cm.getLine(start.line);
    var getIndent = function(line) {
        return CodeMirror.countColumn(line, null, tabSize);
    };
    var myIndent = getIndent(firstLine);
    var last = null;

    for (var i = start.line + 1, end = cm.lastLine(); i <= end; ++i) {
        var curLine = cm.getLine(i);
        var curIndent = getIndent(curLine);
        if (!/^\s*(?:\#.*)?$/.test(curLine)) {
            if (curIndent <= myIndent) {
                break;        
            };
            last = i;
        };
    };

    if (last) {
        // Return foldable region
        return {
            from: CodeMirror.Pos(start.line, firstLine.length),
            to: CodeMirror.Pos(last, cm.getLine(last).length)
        };
    }
});
