const owltest = require('./firebase.js');
const bigo = require('./bigo.js');

const CSModel = (function ($) {
    var Model = function (hashLen, baseURL, bucket) {
        this.bucket      = undefined;
        this.uid         = undefined;
        this.seqnum      = undefined;
        this.ext         = undefined;
        this.filename    = undefined;
        this.hashLen     = hashLen;
        this.shareLen    = (1.5 * hashLen) | 0;
        this.baseURL     = baseURL;
        this.writeBucket = bucket;
    };

    // Default file extension
    var defaultExt = ".py";

    // Number of times to try to write
    var maxRemoteWriteAttempts = 4;

    var checkImportHash = function(mod) {
        var hashpattern = /^(?:\.\/)?([a-zA-Z][a-zA-Z0-9]*)\_([\w]+?)(?:\_(\d+))?\.py$/;
        var result = mod.match(hashpattern);
        var bucket, uid, seqnum;
        var filename;

        if (result === null) {
            return null;
        }

        bucket = result[1];
        uid    = result[2];
        if (result[3] === undefined) {
            seqnum = null;
        } else {
            seqnum = parseInt(result[3]);
        }

        filename = bucket + "_" + uid;
        if (seqnum !== null) {
            filename += "_" + seqnum;
        }
        filename += ".py";

        console.log("Import: bucket: " + bucket + " uid: " + uid +
                    " seqnum: " + seqnum + " filename: " + filename);

        return {bucket: bucket, uid: uid, seqnum: seqnum, filename: filename};
    };

    // Configure model without starting it
    //
    //  view - adapter to view
    Model.prototype.configure = function (view) {
        this.view = view;

        var self = this;

        var errorHandler = function (xhr, textStatus, errorThrown) {
            var msg;

            if (xhr.responseXML !== undefined)
            {
                msg = xhr.responseXML.getElementsByTagName("Message")[0].innerHTML;
            }
            else
            {
                msg = textStatus;
            }

            alert("ERROR: " + msg + "\n\nYour file was unable to be saved.");
        };

        var successHandler = function (responseXML, statusText, xhr, $form) {
            var hash = $form.find("#keyid").val();
            var result = checkImportHash(hash);

            // Update identifiers
            self.bucket   = self.writeBucket;
            self.uid      = result.uid;
            self.seqnum   = result.seqnum;
            self.ext      = defaultExt;
            self.filename = result.filename;

            // Update URL
            self.view.setHash(result.filename);
        };

        var submitOptions = {
            dataType: "xml",
            error: errorHandler,
            success: successHandler
        };

        $('#codeform').submit(function () {
            // Prepare CodeMirror code to be submitted
            view.prepareSubmit();

            // Submit form using Jquery Form Plugin
            $(this).ajaxSubmit(submitOptions);

            // Prevent default form submission behavior
            return false;
        });

	// Configure/initialize firebase
	var fbconfig = {
	    apiKey: "AIzaSyB2iNVP645X7SO_p86fPbLXJSYYX4f68GE",
	    authDomain: "codeskulptor-webapps.firebaseapp.com",
	    databaseURL: "https://codeskulptor-webapps.firebaseio.com",
	    projectId: "codeskulptor-webapps",
	    storageBucket: "codeskulptor-webapps.appspot.com",
	    messagingSenderId: "341714604942"
	};
	firebase.initializeApp(fbconfig);
	
	// Initialize BigO
	this.bigo        = new bigo(view);
    };

    // Start the model
    Model.prototype.start = function () {
    };

    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    // Generate new hash with "len" characters
    Model.prototype.createHash = function (len) {
        var hash = '';
        var i;

        var hlen = len || this.hashLen;

        for (i = 0; i < hlen; i++) {
            hash += chars.charAt((Math.random() * chars.length) | 0);
        }

        return hash;
    };

    // Build filename
    //
    //  bucket - bucket name
    //  uid    - unique hash
    //  seqnum - sequence number
    //  ext    - file extension
    var makeFilename = function (bucket, uid, seqnum, ext) {
        var filename = bucket + "_" + uid;
        if (seqnum >= 0) {
            filename += "_" + seqnum.toString();
        }
        if (ext === undefined) {
            filename += defaultExt;
        } else {
            filename += ext;
        }
        return filename;
    };

    // Check if file already exists in remote storage
    //
    //  self    - model
    //  uid     - unique hash
    //  seqnum  - sequence number
    //  attempt - how many times has this already been tried?
    var checkremote = function (self, uid, seqnum, attempt) {
        var filename;

        attempt = attempt || 1;

        if (attempt > maxRemoteWriteAttempts) {
            alert("Unable to save at this time.");
            return;
        }

        // Set filename
        filename = makeFilename(self.writeBucket, uid, seqnum);
        console.log("Save key: " + filename + " attempt: " + attempt);

        // Check for collision
        var url = self.baseURL.format(self.writeBucket) + filename;
        var xhr = $.ajax({
            url: url,
            type: "HEAD",
            success: function (data, textStatus) {
                // Hash exists, try again
                uid = self.createHash();
                if (seqnum >= 0) {
                    seqnum = 0;
                }
                checkremote(self, uid, seqnum, ++attempt);
            },
            error: function (xhr, textStatus, error) {
                if ((error == "Not Found") || (error == "Forbidden") ||
                    (xhr.status == 403) || (xhr.status == 404)) {
                    // Save code
                    $("#keyid")[0].value = filename;
                    $("#codeform").submit();
                } else {
                    alert("Unable to save at this time.");
                }
            }});
    };

    // Save code
    //
    //  fresh - if true, generate fresh hash,
    //            otherwise just increment sequence number
    Model.prototype.save = function (fresh) {
        var newuid;
        var newseqnum;
        var code;

        // New identifiers
        if (fresh) {
            newuid = this.createHash(this.shareLen);
            newseqnum = -1;
        } else if (this.uid) {
            newuid = this.uid;
            newseqnum = this.seqnum + 1;
        } else {
            newuid = this.createHash();
            newseqnum = 0;
        }

        // Replace tabs and get code
        this.view.replaceLeadingTabs();
        code = this.view.getCode();

        checkremote(this, newuid, newseqnum);
    };

    // Load Remote Files
    var resetHash = function (self) {
        if (self.uid) {
            // Still at old document
            self.view.setHash(self.filename);
        } else {
            // No saved document
            self.view.setHash("");
        }
    };

    // Parse "hash" for validity
    var checkHash = function (hash) {
        var hashpattern = /^#([a-zA-Z][a-zA-Z0-9]*)[\-_]([\w\-]+?)(?:[\-_](\d+))?(\.py)$/;
        var result = hash.match(hashpattern);
        var newbucket, newuid, newseqnum, newext;

        if (result === null) {
            return null;
        }

        newbucket = result[1];
        newuid    = result[2];
        if (result[3] === undefined) {
            newseqnum = -1;
        } else {
            newseqnum = parseInt(result[3]);
        }
        newext = result[4];

        console.log("New: bucket: " + newbucket + " uid: " + newuid +
                    " seqnum: " + newseqnum + " ext: " + newext);

        return {bucket: newbucket, uid: newuid, seqnum: newseqnum, ext: newext};
    };

    // Retrieve file "newHash" from storage
    Model.prototype.loadRemote = function (newHash) {
        if (!newHash) {
            // Empty hash
            this.bucket   = undefined;
            this.uid      = undefined;
            this.seqnum   = undefined;
            this.ext      = undefined;
            this.filename = undefined;
            return;
        }

        var newid = checkHash(newHash);
        var filename = newHash.slice(1);

        if (!newid) {
            // Bogus hash, do nothing
            alert("Invalid file name: " + filename);
            resetHash(this);
            return;
        }

        if (this.filename == filename) {
            // Do nothing, already there (happens after bad hash fails)
            return;
        }

        var self = this;
        var url = self.baseURL.format(newid.bucket) + filename;
        var xhr = $.get(url);
        xhr.done(function (data, textStatus) {
            // Load was successful
            self.view.setCode(data);
            self.reset();
            self.bucket   = newid.bucket;
            self.uid      = newid.uid;
            self.seqnum   = newid.seqnum;
            self.ext      = newid.ext;
            self.filename = filename;
        });
        xhr.fail(function (xhr, textStatus, error) {
            // Load was unsuccessful
            resetHash(self);
            alert("Unable to load file: " + filename);
        });
    };

    Model.prototype.run = function (doResumeIn) {
	var view = this.view;
	var model = this;

	// OwlTest URL
	var url = 'https://2-19-dot-codeskulptor-webapps.appspot.com/owltest/';

	// OwlTest configuration
	var data = {
	    student_URL: "",
	    orig_url_rel: "",
	    imports: "",
	    urlTests: "bigo.bigo_parser.py",
	    urlPylintConfig: "isp.pylint_config.py",
	    desc: "BigO Parser"
	};
	var b64data = btoa(JSON.stringify(data));

	// Actual Form data for POST
	var code = view.getCode();
	var escapedCode = code.replace(/"/g, '\\\"');
	var wrappedCode = 'CODE_STRING = """' + escapedCode + '\n"""';

	var formdata = new FormData();
	var date = new Date();
	var blob = new Blob([wrappedCode], {type: "application/octet-stream"});

	formdata.append('student_url', '');
	formdata.append('data', b64data);
	formdata.append('date_time', date.toISOString());
	formdata.append('student_file', blob, "codefile.py");

	console.log("posting data...");

	// Make POST request
	model.xhr = new XMLHttpRequest();
	model.xhr.open('POST', url, true);
	model.xhr.onreadystatechange = function () {
	    console.log('post state change');
	    console.log(model.xhr);

	    if ((model.xhr.readyState == 4) && (model.xhr.status == 200)) {
		var cidregex = /var firebase_channelID = "([^"]*)";/;
		var match = cidregex.exec(model.xhr.responseText);

		console.log('post success');
		console.log(match);

		if (match && match[1]) {
		    console.log("matched:", match[1]);
		    var tab = {
			makeTabs: function (msg) {
			    try {
				var tabs = msg['tabs'];
				var utf = tabs['UnitTestFailures'];
				var mdicts = utf['msg_dicts'];
				var res = mdicts[0]['msg'];

				model.bigo.process(res);
			    } catch (x) {
				view.stderr(x.toString());
			    }
			},
			showTabs: function () {}
		    };
		    var onOpen = function () {
			console.log("firebase channel opened");
		    };
		    var critical = function (errorMsg) {
			console.log("firebase_critical_error_msg: " + errorMsg);
		    };
		    var errorMsg = function (errorMsg) {
			console.log("firebase_error_msg: " + errorMsg);
		    };
		    model.closeFn = owltest.initOwltestFirebase(match[1], "", tab, errorMsg, onOpen, critical);
		}
	    }
	};
	model.xhr.send(formdata);
    };

    Model.prototype.pause = function ()  {
    };

    Model.prototype.isRunning = function () {
    };
    
    Model.prototype.isBlocked = function () {
    };
    
    Model.prototype.reset = function () {
	if (this.xhr) {
	    // Try to abort POST request
	    this.xhr.abort();
	}
	if (this.closeFn) {
	    // Try to close Firebase channel
	    this.closeFn();
	}

	// Reset the tool
	this.bigo.reset();
    };

    // Return constructor object
    return Model;    
})(jQuery);

module.exports = CSModel;
