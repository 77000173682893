// Entry point to BigO application

// Relevant View Adapter Methods:

// Code Pane:
//  setCode(code) -- set code in code pane
//  getCode()     -- get code from code pane
//  addHighlight(highlightClass, lineStart, lineEnd) -- color given lines background
//  removeHighlight(highlightClass, lineStart, lineEnd) -- remove highlight
//  setLineClickHandler(handler) -- register handler for gutter clicks
//  openDialog(text, handler) -- open a dialog box
//  setGutter(lineNo, text) -- add text to gutter (null clears it)

// Output Pane:
//  stdout(text)  -- print to console (buffered)
//  stddbg(text)  -- print to console in blue (unbuffered)
//  stderr(text)  -- print to console in red (unbuffered)
//  flush()       -- flush buffered output

const math = require('mathjs');

// ~~~~~~~
// Helper hash function (used to generate submission code on completion)
// ~~~~~~~
String.prototype.hashCode = function() {
    var hash = 0;
    if (this.length == 0) {
        return hash;
    }
    for (var i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

// ~~~~~~~
// Helper function: "compare" actual and expected values, allowing for
// minor variance in formatting
// ~~~~~~~
var diffActExp = function (actual, expected) {
    let scope = {
        a: 7,
        b: 19,
        c: 67,
        m: 101,
        p: 139,
        n: 199 
    }

    try {
        var diff = parseInt(math.eval("(" + actual + ") - (" + expected + ")", scope).toString(), 10);
    }
    catch (err) {
        diff = -99999999;
    }
    return diff;
}

const BigOModel = (function () {
    var lchandlerSet = false;

    // view: reference to view adapter
    var BigO = function (view) {
	this.view = view;
        this.progress = false;
    };

    // Process server results and start tool
    // message: JSON encoded results from server
    BigO.prototype.process = function (message) {
	var self = this;
        this.active = true;

        // Display any error message(s)
        //this.view.stdout(message + "\n");
        try {
            var metadata = JSON.parse(message);
        }
        catch (err) {
            self.view.flush();
            return;
        }

        // -------------------------------------------- //
        //               Initialization                 //
        // -------------------------------------------- //
        // Metadata from the backend:
        //     lineMetadata: mapping of fname: lineno: complexity
        //     loopMetadata: mapping of fname: loopstartno: loopendno
        //     dependencies: mapping of fname: dependencies (other fnames)
        //     startLinenos: mapping of fname: startlineno
        //     totalComplexity: mapping of fname: total function complexity
        //     whileLoops: mapping of fname: startlinenos of while loops 
        this.lineMetadata = metadata[0];
        this.loopMetadata = metadata[1];
        this.dependencies = metadata[2];
        this.startLinenos = metadata[3];
        this.totalComplexity = metadata[4];
        this.whileLoops = metadata[5];
        this.hashOps = metadata[6];
        this.varnames = metadata[7];
        this.callees = metadata[8];
        this.hasMultipleOps = metadata[9];

        // Additional metadata gleaned from the above:
        //     endLinenos: mapping of fname: endlineno
        //     totalLoopLinenos: mapping of fname: startlinenos of loops; 
        //         initially only contains the innermost loops, but will be 
        //         expanded during the simplification phase
        //     isNested: mapping of fname: true if it contains additional 
        //         loops, false if it's the innermost loop
        this.endLinenos = {};
        this.outermostLoops = {};
        this.innermostLoops = {};
        this.totalLoopLinenos = {};
        this.isNested = {};

        this.startedMult = {};
        this.startedDegStep = {};
        this.startedSimpl = {};

        if (Object.keys(this.lineMetadata).length == 0) {
            self.view.stdout("No functions found.\n");
            self.view.flush();
        }
        else {
            // Print instructions
            self.view.stddbg("Welcome to Compigorithm!\n");
            self.view.stderr("Please read the following instructions carefully before you begin.\n");
            self.view.stddbg("Compigorithm is designed to train you to methodically compute the worst-case\nalgorithmic complexity of a function in terms of the size of its inputs. For\neach function in the left pane, you will be asked to compute its complexity\nby working through several different steps.");
            self.view.stddbg("Answers should be in the form of O(x), where x is an algebraic expression\ncomposed of the following:\n");
            self.view.stddbg("Constants:\n\xa0\xa0\xa0\xa0-- 1: constant runtime\n");
            self.view.stddbg("Variables:\n\xa0\xa0\xa0\xa0For graphs:\n\xa0\xa0\xa0\xa0-- n: the number of nodes\n\xa0\xa0\xa0\xa0-- m: the number of edges\n\xa0\xa0\xa0\xa0-- d(i): the degree (number of neighbors) of the i-th node");
            self.view.stderr("\xa0\xa0\xa0\xa0\xa0\xa0\xa0NOTE: d(i) is valid within complexity expressions for individual\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0lines and loops; however, you will ultimately be asked to simplify\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0your expressions to represent graphs only in terms of n and m.\n");
            self.view.stddbg("\xa0\xa0\xa0\xa0For all other inputs:\n\xa0\xa0\xa0\xa0-- a, b, c, ...: the size of the 0th, 1st, 2nd, ... inputs.");
            self.view.stddbg("\n\xa0\xa0\xa0\xa0For instance, if a function has two non-graph inputs (arg1, arg2), then\n\xa0\xa0\xa0\xa0the size of arg1 would be \'a\' and the size of arg2 would be \'b\'. If arg1\n\xa0\xa0\xa0\xa0were instead a graph, the size of arg2 would still be \'b\', even though\n\xa0\xa0\xa0\xa0the size of arg1 would be in terms of 'm\' and \'n\' rather than \'a\'. ")
            self.view.stddbg("Operators:\n\xa0\xa0\xa0\xa0-- +: addition\n\xa0\xa0\xa0\xa0-- *: multiplication\n\xa0\xa0\xa0\xa0-- ^: exponentiation\n");
            self.view.stddbg("For each function, you will need to perform the following steps:");
            self.view.stddbg("\xa0\xa0\xa0\xa01. Line-by-line: Analyze the complexity of each line.");
            self.view.stderr("\xa0\xa0\xa0\xa0\xa0\xa0\xa0NOTE: for lines containing loop headers, you will need to specify\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0two things: the number of iterations, and the complexity of the\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0expression that appears to the right of \"for <var> in\" or \"while\".");
            self.view.stderr("\xa0\xa0\xa0\xa0\xa0\xa0\xa0The order in which you'll be asked to provide this information will\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0be different for for vs. while loops. Think about why this might be!");
            self.view.stddbg("\xa0\xa0\xa0\xa02. Multiplication: For each loop, multiply the number of iterations by\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0the sum of the complexities of the lines within the loop. You should\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0keep only one instance of each unique term.");
            self.view.stderr("\xa0\xa0\xa0\xa0\xa0\xa0\xa0For instance, a loop that iterates O(n) times and whose body includes\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0lines which are O(1), O(1), O(n), and O(m) should be simplified to\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0O(n + n^2 + n*m) in this step.");
            self.view.stddbg("\xa0\xa0\xa0\xa03. Degree substitution: Eliminate all instances of d(i) by re-writing\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0in terms of n and m.");
            self.view.stddbg("\xa0\xa0\xa0\xa04. Simplification: Simplify expressions from the previous two steps to\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0eliminate terms that are not maximal in the asymptotic case.");
            self.view.stderr("\xa0\xa0\xa0\xa0\xa0\xa0\xa0O(n + n^2 + n*m) would be simplified to O(n^2 + n*m) in this step.");
            self.view.stddbg("\xa0\xa0\xa0\xa05. Function complexity: Select the terms(s) that are maximal in the\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0asymptotic case and compute the total function complexity.");
            self.view.stddbg("If any of the functions rely on helper functions (excluding built-in Python\nlibraries), you must compute the complexity of those helper functions first.\nFunctions with dependencies will appear in grey; available functions in white.");
            self.view.stddbg("Select a line to begin working by clicking on its line number. Switch to a\ndifferent line by clicking on it, or by pressing enter to advance to the\nnext available line. Once you've completed the individual lines and loops,\nyou can select the function header to input the overall function complexity.");
        }

        // Keep track of functions that have been completed and (per-function)
        // lines that need to be completed, respectively
        this.completedFnames = [];
        this.fnameTodos = {};
        this.allowableFnames = [];

        // Keep track of which line(s) are highlighted, so that we can 
        // un-highlight them when we move to a different line
        this.activelines = [];
        this.activeFname = null;

        // Keep track of the gutter text, so that we can handle loops appropriately
        this.gutterText = {};

        // All sorts of extra metadata
        this.loopIndent = {};
        this.loopParent = {};
        this.halfDone = {};
        this.loopHalfDone = {};
        this.needsDegStep = {};
        this.needsSimpl = {};
        this.edgeProdLines = {};

        // ~~~~~~~
        // One-time initialization: operations that should not be performed 
        // again on reset
        // ~~~~~~~
        // Replace lineno strings with integers, and also shift by 1
        // Populate fnameTodos
        for (var fname in this.lineMetadata) {
            var lines = this.lineMetadata[fname][1];
            var linenos = Object.keys(lines);
            var newLineMetadata = {};
            for (var i in linenos) {
                var linePlusOne = parseInt(linenos[i], 10) + 1;
                newLineMetadata[linePlusOne] = this.lineMetadata[fname][1][linenos[i]];
            }

            this.lineMetadata[fname] = newLineMetadata;
        }

        // Shift startLinenos, loopMetadata, whileLoops, hashOps, and callees by 1 
        // (and also convert to ints)
        var newLoopMetadata = {};
        var newStartLinenos = {};
        var newWhileLoops = {};
        var newHashOps = {};
        var newCallees = {};
        var newMultOps = {};
        for (var fname in this.startLinenos) {
            newStartLinenos[fname] = parseInt(this.startLinenos[fname], 10) + 1;
            newLoopMetadata[fname] = {};
            newWhileLoops[fname] = [];
            newHashOps[fname] = [];
            newMultOps[fname] = [];
            newCallees[fname] = {};

            for (var loopStart in this.loopMetadata[fname]) {
                newLoopMetadata[fname][parseInt(loopStart) + 1] = this.loopMetadata[fname][loopStart] + 1;
            }

            for (var idx in this.whileLoops[fname]) {
                newWhileLoops[fname].push(parseInt(this.whileLoops[fname][idx]) + 1);
            }

            for (var idx in this.hashOps[fname]) {
                newHashOps[fname].push(parseInt(this.hashOps[fname][idx]));
            }

            for (var idx in this.hasMultipleOps[fname]) {
                newMultOps[fname].push(parseInt(this.hasMultipleOps[fname][idx]) + 1);
            }

            for (var idx in this.callees[fname]) {
                newCallees[fname][parseInt(idx) + 1] = this.callees[fname][idx];
            }
        }

        this.loopMetadata = newLoopMetadata;
        this.startLinenos = newStartLinenos;
        this.whileLoops = newWhileLoops;
        this.hashOps = newHashOps;
        this.callees = newCallees;
        this.hasMultipleOps = newMultOps;

        // Find the indentation level & parent of each loop
        // For all loops, also find the total complexity contribution
        // Find the innermost loops, to initialize totalLoopLinenos
        for (var fname in this.loopMetadata) {

            this.startedMult[fname] = false;
            this.startedDegStep[fname] = false;
            this.startedSimpl[fname] = false;

            this.innermostLoops[fname] = [];
            this.outermostLoops[fname] = [];
            var loopStartLinenos = Object.keys(this.loopMetadata[fname]);
            loopStartLinenos.sort(this.saneSortInt);

            var parentIndent = -1;
            var indent = 0;
            var parentLoopStart = null;
            var prevLoopStart = null;

            for (var i in loopStartLinenos) {
                var startLineno = parseInt(loopStartLinenos[i], 10);
                this.isNested[startLineno] = false;
                this.halfDone[startLineno] = false;
                this.loopHalfDone[startLineno] = false;

                if (prevLoopStart && this.loopMetadata[fname][prevLoopStart] >= this.loopMetadata[fname][startLineno]) { 
                    // Within the previous loop -> one indentation level deeper
                    parentLoopStart = prevLoopStart;
                    parentIndent = indent;
                }
                else { 
                    // Not within the previous loop -> check to see if we're
                    // outside of any older parent loop(s); if so, decrease 
                    // the indentation level accordingly
                    while (parentLoopStart && this.loopMetadata[fname][parentLoopStart] < startLineno) {
                        parentLoopStart = this.loopParent[parentLoopStart];
                        parentIndent = parentIndent - 1;
                    }
                }
                this.isNested[parentLoopStart] = true;
                indent = parentIndent + 1;
                this.loopIndent[startLineno] = indent;
                this.loopParent[startLineno] = parentLoopStart;
                if (parentLoopStart == null) {
                    this.outermostLoops[fname].push(startLineno);
                }
                var idx = this.innermostLoops[fname].indexOf(parentLoopStart);
                if (idx != -1) {
                    this.innermostLoops[fname].splice(idx, 1);
                }
                prevLoopStart = startLineno;
                this.innermostLoops[fname].push(parseInt(startLineno, 10));
            }

            // Put only the innermost loops in totalLoopLinenos, since we'll
            // present those to the user first
            this.totalLoopLinenos[fname] = this.innermostLoops[fname];
            this.totalLoopLinenos[fname].sort(this.saneSortInt);
        }

        // ~~~~~~~
        // Helper function: get name of function containing given line
        // ~~~~~~~
        var getFname = function (lineNo) {
            var containingFname = null;
            var containingStartLineno = 0;
            for (var fname in self.startLinenos) {
                var startLineno = self.startLinenos[fname];
                if (startLineno <= lineNo) {
                    if (containingFname == null || containingStartLineno <= startLineno) {
                        containingFname = fname; 
                        containingStartLineno = startLineno;
                    }
                }
            }
            return containingFname;
        }   

        // ~~~~~~~
        // Helper function: get start lineno of innermost loop containing given line
        // ~~~~~~~
        var getLoop = function (lineno) {
            var fname = getFname(lineno);
            var containingLineno = -1;
            for (var startLineno in self.loopMetadata[fname]) {
                if (startLineno < lineno && self.loopMetadata[fname][startLineno] >= lineno) {
                    if (containingLineno == -1 || containingLineno < startLineno) {
                        containingLineno = parseInt(startLineno, 10);

                    }
                }
            }
            return containingLineno;
        }

        // Populate needsDegStep and needsSimplStep
        for (var fname in this.lineMetadata) {

            var linenos = Object.keys(this.lineMetadata[fname]);
            linenos.sort(this.saneSortInt);
            this.needsDegStep[fname] = [];
            this.needsSimpl[fname] = [];
            for (var i in linenos) {
                var lineno = parseInt(linenos[i], 10);
                var metadata = this.lineMetadata[fname][lineno];

                if ((metadata.length == 1 && metadata[0].indexOf("p") != -1 && getLoop(lineno) == -1) || (metadata.length > 1 && metadata[2].indexOf("p") != -1 && this.loopParent[lineno] == null)) {
                    this.needsDegStep[fname].push(lineno);
                }  

                if ((metadata.length > 1) && (diffActExp(metadata[3], metadata[4]) != 0)) {
                    this.needsSimpl[fname].push(lineno);
                }

            }
        }

        // Populate edgeProdLines, so that if they get the degree substitution
        // step wrong we can quickly point them in the direction of the lines
        // that form the n*deg(i)->m term
        for (var fname in this.lineMetadata) {
            this.edgeProdLines[fname] = {};
            for (var idx in this.needsDegStep[fname]) {
                var lineno = this.needsDegStep[fname][idx];
                if (lineno in this.loopMetadata[fname]) {
                    var outerN = -1;
                    var innerDeg = -1;
                    var i = 0;
                    while ((i < ((self.loopMetadata[fname][lineno] + 1) - (lineno))) && (outerN < 0 || innerDeg < 0)) {
                        // Skip blank lines and comments
                        if (!((lineno + i) in self.lineMetadata[fname])) {
                            i = i + 1;
                            continue;
                        }
                        if ((lineno + i) in self.loopMetadata[fname]) {
                            if (self.lineMetadata[fname][lineno + i][1] == "n") {
                                outerN = (lineno + i);
                            }
                            else if (self.lineMetadata[fname][lineno + i][1] == "p") {
                                innerDeg = (lineno + i);
                            }
                        }
                        i = i + 1;
                    }
                    if (outerN > 0 && innerDeg > 0) {
                        this.edgeProdLines[fname][lineno] = [outerN, innerDeg];
                    }
                }
            }
        }
        this.init();

        // -------------------------------------------- //
        //              Helper Functions                //
        // -------------------------------------------- //
        // ~~~~~~~
        // Helper function: un-highlight all old line(s)
        // ~~~~~~~
        var unHighlight = function (newActiveLineno) {
            if (self.activelines.length > 0) {
                for (var i in self.activelines) {
                    var activeLineno = self.activelines[i];
                    self.view.removeHighlight('blueline', activeLineno);
                    self.view.addHighlight('whiteline', activeLineno);

                    // Clear arrow from gutter, if we moved on without
                    // completing the previous line
                    if (activeLineno in self.gutterText) {
                        if (self.gutterText[activeLineno] == "\u2192") {
                            self.view.setGutter(activeLineno, null);
                            delete self.gutterText[activeLineno];
                        } 
                        else if (activeLineno != newActiveLineno) {
                            // For partially-completed loop headers, clear
                            // arrow ONLY (leaving complexity info)
                            self.view.setGutter(activeLineno, self.gutterText[activeLineno]);
                        }

                    }
                }
	    }
            self.activelines = [];
        }

        // ~~~~~~~
        // Helper function: un-highlight only those line(s) within the loop 
        // starting at the given lineno
        // ~~~~~~~
        var unHighlightLoop = function (lineno, color) {
            var i = 0;
            var fname = self.activeFname;
            while (i < ((self.loopMetadata[fname][lineno] + 1) - (lineno))) {
                self.view.removeHighlight(color, lineno + i);
                var idx = self.activelines.indexOf(lineno + 1);
                if (idx != -1) {
                    self.activelines.splice(idx, 1);
                }
                i = i + 1;
            }
        }

        // ~~~~~~~
        // Helper function: un-highlight entire function
        // ~~~~~~~
        var unHighlightFxn = function (fname, color) {
            var start = self.startLinenos[fname];
            var end = self.endLinenos[fname];

            unHighlight(-1);
            var i = start;
            while (i < (end + 1)) {
                self.view.removeHighlight(color, i);
                i = i + 1;
            }
            self.view.flush();
        }

        // ~~~~~~~
        // Helper function: highlight the loop beginning at the given lineno
        // ~~~~~~~
        var highlightLoop = function (lineno, color) {
            var i = 0;
            var fname = self.activeFname;
            while (i < ((self.loopMetadata[fname][lineno] + 1) - (lineno))) {
                self.view.removeHighlight("whiteline", lineno + i);
                self.view.removeHighlight("ltblueline", lineno + i);
                self.view.addHighlight(color, lineno + i);
                if (color == "blueline") {
                    self.activelines.push(lineno + i); 
                }
                i = i + 1;
            }
        }

        // ~~~~~~~
        // Helper function: highlight available-for-total loops in light blue
        // ~~~~~~~
        var highlightLoops = function (fname, lineno) {
            var i = 0;

            var iterable = self.totalLoopLinenos[fname];
            while (i < iterable.length) {
                var lineno2 = iterable[i];
                if (lineno2 != lineno) {
                    highlightLoop(lineno2, "ltblueline")
                }
                i = i + 1;
            }
        }

        // ~~~~~~~
        // Helper function: highlight available-for-substitution nbr blocks
        // ~~~~~~~
        var highlightNbrs = function (fname, lineno) {
            var i = 0;
            while (i < self.needsDegStep[fname].length) {
                var lineno2 = self.needsDegStep[fname][i];
                if (lineno2 != lineno) {
                    if (lineno2 in self.loopMetadata[fname]) {
                        highlightLoop(lineno2, "ltblueline")
                    }
                    else {
                        self.view.removeHighlight("whiteline", lineno2);
                        self.view.addHighlight("ltblueline", lineno2);
                    }
                }
                i = i + 1;
            }
            self.view.flush();
        }

        // ~~~~~~~
        // Helper function: highlight available-for-substitution nbr blocks
        // ~~~~~~~
        var highlightSimpl = function (fname, lineno) {
            var i = 0;
            while (i < self.needsSimpl[fname].length) {
                var lineno2 = self.needsSimpl[fname][i];
                if (lineno2 != lineno) {
                    if (lineno2 in self.loopMetadata[fname]) {
                        highlightLoop(lineno2, "ltblueline")
                    }
                    else {
                        self.view.removeHighlight("whiteline", lineno2);
                        self.view.addHighlight("ltblueline", lineno2);
                    }
                }
                i = i + 1;
            }
            self.view.flush();
        }

        // ~~~~~~~
        // Helper function: highlight available functio in its entirety
        // ~~~~~~~
        var highlightFxn = function (fname, color) {
            var start = self.startLinenos[fname];
            var end = self.endLinenos[fname];

            unHighlight(-1);
            var i = start;
            while (i < (end + 1)) {
                self.view.addHighlight(color, i);
                i = i + 1;
            }
            self.view.flush();
        }

        // ~~~~~~~
        // Helper function: get pad to prepend to gutter text, based on level
        // of nesting in corresponding code
        // ~~~~~~~
        var getPad = function (lineno) {
            // Find level of nesting
            var indent = 0;
            var loopStartLineno = getLoop(lineno);
            if (loopStartLineno != -1) {
                indent = self.loopIndent[loopStartLineno] + 1;
            }

            // Add four spaces per level
            var pad = "";
            while (indent > 0) {
                pad = pad + "\xa0\xa0";
                indent = indent - 1;
            }

            if (loopStartLineno != -1 && lineno in self.loopMetadata[self.activeFname]) {
                pad = pad + "\xa0";
            }

            return pad;
        }

        // ~~~~~~~
        // Helper function: append arrow to partial gutter text
        // ~~~~~~~
        var appendArrow = function (text) {
            var len = text.length;
            while (len < 49) { // 53
                text = text + "\xa0";
                len = len + 1;
            }
            text = text + "\u2192";
            return text;
        }

        // ~~~~~~~
        // Helper function: move to next incomplete *individual* line within this function
        // ~~~~~~~
        var tryNext = function (lineno, callback, lineSeq) {
            var fname = getFname(lineno);
            if (callback == totalloophandler && self.loopParent[lineno] != null) {
                lineno = self.loopParent[lineno] - 1;
            }

            var i = 1;
            while (!callback(lineno + i)) { // Skip blank lines & comments
                i = i + 1;
            }
            if (lineno + i > self.endLinenos[fname]) { // Wraparound
                callback(lineSeq[fname][0]);
            }
        }

        // ~~~~~~~
        // Helper function: getting the next incomplete line
        // ~~~~~~~
        var nextPrompt = function (fname, lineno) {
            // If we're done with all the lines in this function, begin asking
            // for the totalLoopContribs (to be followed by the total function
            // complexity). If we're done with all of those, begin asking for
            // nbr substitution.
            var idx;
            if (self.fnameTodos[fname].length == 0) {
                if (self.totalLoopLinenos[fname].length > 0) {
                    var idx = self.totalLoopLinenos[fname].indexOf(lineno);
                    if (!self.startedMult[fname]) {
                        self.view.stddbg("Done with step 1 for function " + fname + ".\nMoving on to step 2 (loop multiplication).");
                        self.startedMult[fname] = true;
                    }
                    unHighlight(-1);

                    // Highlight all of the available loops in a soft blue
                    highlightLoops(fname, -1);
                    if (self.totalLoopLinenos[fname].length == (idx + 1)) {
                        totalloophandler(self.totalLoopLinenos[fname][0]);
                    }
                    else {
                        totalloophandler(self.totalLoopLinenos[fname][idx + 1]);
                    }
                }
                else if (self.needsDegStep[fname].length > 0) { // no more multiplication, but need degree substitution
                    var idx = self.needsDegStep[fname].indexOf(lineno);
                    if (!self.startedDegStep[fname]) {
                        self.view.stddbg("Done with steps 1-2 for function " + fname + ".\nMoving on to step 3 (degree substitution).");
                        self.startedDegStep[fname] = true;
                    }
                    unHighlight(-1);
                    highlightNbrs(fname, self.needsDegStep[fname][0]);

                    if (self.needsDegStep[fname].length == (idx + 1)) {
                        nbrhandler(self.needsDegStep[fname][0]);
                    }
                    else {
                        nbrhandler(self.needsDegStep[fname][idx + 1]);
                    }
                }
                else if (self.needsSimpl[fname].length > 0) { // no more d(i), but need to simplify
                    highlightSimpl(fname, -1);
                    var idx = self.needsSimpl[fname].indexOf(lineno);
                    if (!self.startedDegStep[fname]) {
                        self.startedDegStep[fname] = true;
                        self.view.stddbg("No degree substitution needed.");
                    }
                    if (!self.startedSimpl[fname]) {
                        self.view.stddbg("Done with steps 1-3 for function " + fname + ".\nMoving on to step 4 (simplification).");
                        self.startedSimpl[fname] = true;
                    }
                    unHighlight(-1);

                    if (self.needsSimpl[fname].length == (idx + 1)) {
                        totalloophandler(self.needsSimpl[fname][0]);
                    }
                    else {
                        totalloophandler(self.needsSimpl[fname][idx + 1]);
                    }
                }
                else {
                    if (!self.startedDegStep[fname]) {
                        self.startedDegStep[fname] = true;
                        self.view.stddbg("No degree substitution needed.");
                    }
                    if (!self.startedSimpl[fname]) {
                        self.startedSimpl[fname] = true;
                        self.view.stddbg("No simplification needed.");
                    }

                    self.view.stddbg("Done with steps 1-4 for function " + fname + ".\nMoving on to step 5 (total function complexity).");
                    unHighlight(-1);
                    fxnhandler(fname);
                }
            }

            // Otherwise, ask for the next incomplete line
            else {
                tryNext(lineno, linehandler, self.fnameTodos);
            }
        }

        // ~~~~~~~
        // Helper function: prepend +s to lines within loops (if the loop 
        // header is already complete)
        // ~~~~~~~
        var loopNumitersDone = function (lineno) {
            var fname = self.activeFname;
            if (self.whileLoops[fname].indexOf(lineno) != -1) {
                 return self.halfDone[lineno];
            }
            else {
                 return self.fnameTodos[fname].indexOf(lineno) == -1;
            }
        }

        // ~~~~~~~
        // Helper function: prepend +s to lines within loops (if the loop 
        // header is already complete)
        // ~~~~~~~
        var prependPlus = function (lineno, text) {
            var containingLoop = getLoop(lineno);

            // Make sure the loop's numiters has been done, otherwise don't show a plus.
            if (containingLoop != -1 && (loopNumitersDone(containingLoop) == true)) {
                var newText = "";

                // Also make sure this isn't the *first* line in a for loop
                if ((containingLoop == (lineno - 1)) && (self.whileLoops[fname].indexOf(parseInt(containingLoop, 10)) == -1)) {
                    newText = "\xa0" + text;
                }
                else { // not the first line of a loop
                    var textIdx = text.indexOf("O"); 

                    if (text.charAt(textIdx - 1) == "(") {
                        textIdx = textIdx - 1;
                        var first = text.slice(0, textIdx - 2);
                        if (!(lineno in self.loopMetadata[self.activeFname])) {
                            first = "\xa0\xa0" + first;
                        }
                    }
                    else {
                        var first = text.slice(0, textIdx - 2);
                        if (!(lineno in self.loopMetadata[self.activeFname])) {
                            first = "\xa0" + first;
                        }
                    }
                    var rest = text.slice(textIdx);
                    newText = first + "+ " + rest;
                }

                // In either case, append closing parens as needed
                text = appendParens(newText, containingLoop, lineno);
            }
            return text;
        }

        // ~~~~~~~
        // Helper function: prepend +s to lines within loops (if the loop 
        // header is already complete)
        // ~~~~~~~
        // Check all lines below and prepend "+"s as needed
        var prependAllPluses = function (fname, lineno) {
            var newText = "";
            var i = lineno + 1;
            var end = self.loopMetadata[fname][lineno];
            while (i < (end + 1)) {
                if (i in self.gutterText) {
                    var newText = prependPlus(i, self.gutterText[i]);
                    self.gutterText[i] = newText;
                    self.view.setGutter(i, newText);
                    self.view.flush();
                }
                i = i + 1;
            }
            self.view.flush();
        }

        // ~~~~~~~
        // Helper function: append parens to loop aggregation in steps 2-4
        // ~~~~~~~
        var appendParensAgg = function (newText, loopno) {
            var fname = self.activeFname;
            var end = self.loopMetadata[fname][loopno]; // end of the loop we're aggregating
            var lineno = end;
            var parent = self.loopParent[loopno]; // containing loop
            
            while (parent != null) {
                var parentEnd = self.loopMetadata[fname][parent]; // end of containing loop
                if (parentEnd != end) {
                    break;
                }
                newText = newText + "))";
                loopno = parent;
                var parent = self.loopParent[loopno];
            }
            return newText;
        }

        // ~~~~~~~
        // Helper function: append parens to last line in loop
        // ~~~~~~~
        var appendParens = function (newText, containingLoop, lineno) {
            var fname = self.activeFname;
            var parent = null;
            var end = self.loopMetadata[fname][containingLoop];
            while (lineno == end && containingLoop != null) {
                newText = newText + "))";
                var parent = self.loopParent[containingLoop];
                end = self.loopMetadata[fname][parent];
                containingLoop = parent;
            }
            return newText;
        }

        // ~~~~~~~
        // Helper function: check for malformed input to dialog box
        // ~~~~~~~
        var checkInput = function (text, lineno, callback, total) {
            var malformed = false;
            text = text.replace(/d\(i\)/g, "p");

            if ((text.match(/O\(/g)||[]).length > 1) {
                return "OO";
            }

            try {
                var retval = text.split("O(")[1];
                retval = retval.split(")");
                retval.pop();
                retval = retval.join(")");
            }
            catch (err) {
                malformed = true;
            }

            var origText = text.replace(/p/g, "d(i)");
            var errMsg = "Invalid input \"" + origText + "\"";

            var intText = parseInt(retval, 10).toString();

            if (intText != "NaN" && intText != 1) {
                self.view.stderr(errMsg + "; the only allowable constant term is 1.");
                return "";
            }

            // Check opening "O("
            if (malformed) {
                self.view.stderr(errMsg + "; input should be of the form O(...).");
                callback(lineno);
                return "";
            }

            // Check parentheses
            else if (text.indexOf(")") == -1) {
                self.view.stderr(errMsg + "; missing \")\".");
                callback(lineno);
                return "";
            }
            else if ((text.match(/\)/g)||[]).length != (text.match(/\(/g)||[]).length) {
                self.view.stderr(errMsg + "; mismatched parentheses.");
                callback(lineno);
                return "";
            } 
            else if (retval.indexOf(")") > -1 && total) {
                self.view.stderr(errMsg + "; in this phase, you should fully expand the\nterms (no parentheses other than in d(i) expressions).");
                callback(lineno);
                return "";
            }

            // Check for use of ** instead of ^
            else if (retval.indexOf("**") >= 0) {
                self.view.stderr(errMsg + "; remember to use \"^\" (not \"**\") for exponentiation.");
                callback(lineno);
                return "";
            }

            // Check for empty expressions
            else if (retval.trim().length == 0) {
                self.view.stderr(errMsg + "; empty expression.");
                callback(lineno);
                return "";
            }

            // Check for invalid variable names
            var retvalCopy = retval.replace(/\+/g, " ");
            retvalCopy = retvalCopy.replace(/\^/g, " ");
            retvalCopy = retvalCopy.replace(/\*/g, " ");
            retvalCopy = retvalCopy.replace(/1/g, " ");
            retvalCopy = retvalCopy.replace(/\(/g, " ");
            retvalCopy = retvalCopy.replace(/\)/g, " ");
            var fname = getFname(lineno);
            for (var idx in self.varnames[fname]) {
                var varname = self.varnames[fname][idx];
                if (varname == "d(i)") {
                    retvalCopy = retvalCopy.replace(new RegExp("p", "g"), " ");
                }
                else {
                    retvalCopy = retvalCopy.replace(new RegExp(varname, "g"), " ");
                }
            }
            retvalCopy = retvalCopy.split(" ").join("");

            if (retvalCopy.length > 0 && parseInt(retvalCopy, 10).toString() == "NaN") {
                self.view.stderr(errMsg + "; input should contain only valid variable names\n(" + self.varnames[fname].join(", ") + ") and operators (+, *, ^).");

                // Is a call to a helper
                if ((lineno) in self.callees[fname]) {
                    for (var calleeIdx in self.callees[fname][lineno]) {
                        var calleeName = self.callees[fname][lineno][calleeIdx];
                        for (var idx in self.varnames[calleeName]) {
                            var varname = self.varnames[calleeName][idx];
                            if (varname == "d(i)") {
                                retvalCopy = retvalCopy.replace(new RegExp("p", "g"), " ");
                            }
                            else {
                                retvalCopy = retvalCopy.replace(new RegExp(varname, "g"), " ");
                            }
                        }
                    }

                    retvalCopy = retvalCopy.split(" ").join("");
                    if (retvalCopy.length == 0 || parseInt(retvalCopy, 10).toString() != "NaN") {
                        self.view.stderr("Hint: What are the sizes of the inputs to the callee (" + calleeName + ")\nin terms of the sizes of the inputs to the caller (" + fname + ")?");
                    }

                retvalCopy = retvalCopy.split(" ").join("");

                }  
                callback(lineno);
                return "";
            }

            return retval;
        }

        // ~~~~~~~
        // Helper function: check for errors during simplification; 
        // ~~~~~~~
        var simplifyErrCheck = function (expr, fullText, callback, lineno) {
            try {
                expr = math.simplify(expr).toString();
            }
            catch (err) {
                self.view.stderr("Invalid input \"" + fullText + "\"; malformed expression.");
                callback(lineno);
            }
            return expr;
        }

        // ~~~~~~~
        // Helper function: on intermediate or total loop simplification, 
        // for clearing the individual body lines and replacing with a combined
        // expression in the header.
        // ~~~~~~~
        var clearLoopUpdateHeader = function (lineno, bigo, roundOne) {
            var fname = self.activeFname;
            var oldText = self.gutterText[lineno];

            // Clear the lines within the loop
            var i = 0;
            while (i < self.activelines.length) {
               self.gutterText[self.activelines[i]] = "";
               self.view.setGutter(self.activelines[i], null);
               i = i + 1;
            }

            // If a for loop, keep the part before numiters (for the loop header)
            var newText = "";
            if (self.whileLoops[fname].indexOf(parseInt(lineno, 10)) == -1) {
                if (roundOne) {
                    var startIdx = oldText.indexOf(") + (O(") + 4;
                }
                else {
                    var startIdx = oldText.indexOf(" + O(") + 3;
                }
                newText = oldText.slice(0, startIdx) + "O(" + formatExpr(bigo) + ")";
                newText = appendParensAgg(newText, lineno);
            }
            else {
                // For while loops, replace the whole thing
                newText = "\xa0\xa0" + getPad(lineno) + "O(" + formatExpr(bigo) + ")";
                newText = prependPlus(lineno, newText);
                //if (getLoop(lineno) < 0) {
                //    newText = "\xa0" + newText;
                //}
            }
            return newText;
        }

        // ~~~~~~~
        // Helper function: mark this loop as totally done, and expand 
        // totalLoopLinenos to include parent if ready.
        // ~~~~~~~       
        var finalizeLoop = function (lineno) {
            var fname = self.activeFname;
            var idx = self.totalLoopLinenos[fname].indexOf(lineno);
            self.totalLoopLinenos[fname].splice(idx, 1);
            self.view.flush();

            // Expand totalLoopLinenos to include parent of lineno, if all
            // of its other children are done
            var parent = self.loopParent[lineno];
            var parentReady = true;
            if (parent != null) {
                var i = 0;
                while (i < self.totalLoopLinenos[fname].length) {
                    var otherLoop = self.totalLoopLinenos[fname][i];
                    var otherParent = self.loopParent[otherLoop];
                    while (otherParent != null) {
                        if (otherParent == parent) {
                            parentReady = false;
                            break;
                        }
                        otherLoop = otherParent;
                        otherParent = self.loopParent[otherLoop];
                    }
                    i = i + 1;
                }
                if (parentReady) {
                    self.totalLoopLinenos[fname].push(parent);
                    self.totalLoopLinenos[fname].sort(this.saneSortInt);
                    highlightLoop(parent, "ltblueline");
                }
            }
        }

        // ~~~~~~~
        // Helper function: if they get the loop multiplication step wrong,
        // find and return the sub-lines from which they missed terms.
        // ~~~~~~~       
        var findMissingTermsMult = function (actual, expected, lineno) {
            var fname = getFname(lineno);
            actual = actual.split("+");
            expected = expected.split("+");
            var numiters = self.lineMetadata[fname][lineno][1];

            // Find missing terms
            var missingTermLines = [];
            for (var idx1 in expected) {
                term1 = expected[idx1];

                // Search for an equivalent (to term1) term in expected
                var found = false;
                for (var idx2 in actual) {
                    term2 = actual[idx2];
                    if (diffActExp(math.simplify(term1).toString(), math.simplify(term2).toString()) == 0) {
                        found = true;
                        break;
                    }
                }

                // If not found, figure out which line(s) they missed
                if (!found) { // term1 is missing

                    // Look at each line within the loop to see if it 
                    // contributed the missing term
                    var i = 0;
                    var endIdx = ((self.loopMetadata[fname][lineno] + 1) - (lineno));
                    while (i < endIdx) {

                        // Skip blank lines and comments
                        if (!((lineno + i) in self.lineMetadata[fname])) {
                            i = i + 1;
                            continue;
                        }

                        var expTerms = []; // expected terms *from THIS line*
                        if (i != 0 && ((lineno + i) in self.loopMetadata[fname])) { 
                            // Inner loop
                            expTerms = self.lineMetadata[fname][lineno + i][2].split("+");
                            endIdx = i;
                        }
                        else if ((i == 0 && ((lineno + i) in self.whileLoops[fname])) || (i != 0)) { 
                            // Single line OR while loop header
                            expTerms = self.lineMetadata[fname][lineno + i][0].split("+");
                        }
                        else { 
                            // For loop header; nothing to see here
                            i = i + 1;
                            continue;
                        }

                        // Multiply each sub-term by outer numiters
                        var bigo = numiters.toString() + "*" + expTerms[0].toString();
                        for (var idx3 in expTerms) {
                            if (idx3 == 0) {
                                continue;
                            }
                            bigo = bigo + " + " + numiters.toString() + "*" + expTerms[idx3].toString();
                        }
                        expTerms = bigo.split("+");

                        // Look at each individual sub-term within the product
                        for (var idx2 in expTerms) {
                            term2 = expTerms[idx2];
                            if (diffActExp(math.simplify(term1).toString(), math.simplify(term2).toString()) == 0) {
                                found = true;
                                break;
                            }
                        }

                        if (found) { // missing term came from (lineno + i)
                            if (missingTermLines.indexOf(lineno + i) == -1) {
                                missingTermLines.push(lineno + i);
                            }
                            break;
                        }

                        // Not yet found -> move onto the next line in the loop
                        i = i + 1;
                    }
                }
            }
            return missingTermLines;
        }

        // ~~~~~~~
        // Helper function: get prompt to display on step1a for loops.
        // ~~~~~~~       
        var step1aText = function (lineno) {
            var fname = getFname(lineno);
            var text = "[Step 1a] Complexity of loop expression ";
            if (self.whileLoops[fname].indexOf(lineno) != -1) {
                text = text + "(to the right of \"while\")";
            }
            else {
                text = text + "(to the right of \"in\")";
            }
            text = text + " in line " + lineno.toString() + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
            return text;
        }

        // ~~~~~~~
        // Helper function: if they get the loop multiplication step wrong,
        // find and return any duplicate terms.
        // ~~~~~~~       
        var findDuplicateTerms = function (actual) {
            actual = actual.split("+");

            // Find duplicate terms
            var uniqueTerms = [];
            var duplicateTerms = [];
            for (var idx1 in actual) {
                term1 = actual[idx1].trim();
                var unique = true;
                for (var idx2 in uniqueTerms) {
                    term2 = uniqueTerms[idx2].trim();
                    if (diffActExp(math.simplify(term1).toString(), math.simplify(term2).toString()) == 0) {
                        unique = false;
                        duplicateTerms.push(term1);
                        break;
                    }
                }
                if (unique) { 
                    uniqueTerms.push(term1);
                }
            }

            // Replace p -> d(i) for displaying feedback to students
            for (var idx in duplicateTerms) {
                duplicateTerms[idx] = duplicateTerms[idx].replace(/p/g, "d(i)");
            }

            return duplicateTerms;
        }

        // ~~~~~~~
        // Helper function: concatenate the loop body annotations into a 
        // one-line expression.
        // ~~~~~~~       
        var getLoopAnnotations = function (startLineno) {
            var endLineno = self.loopMetadata[self.activeFname][startLineno];

            // First, handle the loop header (strip expression from left, 
            // arrow from right, and whitespace)
            var loopTxt = self.gutterText[startLineno].split("+");
            if (loopTxt[0].trim().length == 0 && (self.whileLoops[self.activeFname].indexOf(startLineno) == -1)) {
                loopTxt.splice(0, 1);
            }
            loopTxt.splice(0, 1);
            loopTxt = loopTxt.join("+");
            loopTxt = loopTxt.split("\u2192")[0];
            loopTxt = loopTxt.trim();

            for (lineno = startLineno + 1; lineno <= endLineno; lineno++) {
                if (!((lineno) in self.lineMetadata[fname])) {
                    continue;
                }

                var lineTxt = self.gutterText[lineno];
                lineTxt = lineTxt.trim();
                loopTxt = loopTxt + lineTxt;
                if ((lineno != endLineno) && ((lineno + 1) in self.lineMetadata[fname]) && (self.gutterText[lineno + 1].trim()[0] == "+")) {
                    loopTxt = loopTxt + " ";
                }
            }
            return loopTxt;
        }

        // ~~~~~~~
        // Helper function: format a string representing a mathematical 
        // expression for output
        // ~~~~~~~       
        var formatExpr = function (expr) {
            var terms = expr.split("+");
            for (var i = 0; i < terms.length; i++) {
                var term = terms[i].trim();
                var termParts = terms[i].split(" ");
                term = termParts.join("");
                terms[i] = term;
            }
            return terms.join(" + ");
        }

        // ~~~~~~~
        // Helper function: returns the set of variables used in the given expression
        // ~~~~~~~       
        var getVars = function(expr) {
            var varsInExpr = [];
            for (var idx in self.varnames[self.activeFname]) {
                var varname = self.varnames[self.activeFname][idx];
                if (varname == "d(i)" || varname == "m") {
                    varname = "n";
                }
                if (expr.indexOf(varname) > -1 && varsInExpr.indexOf(varname) == -1) {
                    varsInExpr.push(varname);
                }
            }
            return varsInExpr;
        }

 
        // ~~~~~~~
        // Helper function: returns the difference of the two input sets
        // ~~~~~~~       
        var setDifference = function(setA, setB) {
            var difference = [];
            for (var idx in setA) {
                difference.push(setA[idx]);
            }
            for (var idx in setB) {
                var idx2 = difference.indexOf(setB[idx]);
                if (idx2 > -1) {
                    difference.splice(idx2, 1);
                }
            }
            return difference;
        }

        // --------------------------------------------//
        //               Click Handlers                //
        // --------------------------------------------//
        // ~~~~~~~
	// Top-level handler for line clicks, which may redirect to 
        // totalloophandler or fxnhandler as appropriate
        // ~~~~~~~
	var linehandler = function (lineno) {
            fname = getFname(lineno);
            self.activeFname = fname;

            // Haven't hit 'Start' again after 'Reset'
            if (!self.active) {
                self.view.stderr("Please press \'Start\' to begin the exercise.");
                return false;
            }

            // Already finished this function
            else if (self.completedFnames.indexOf(fname) > -1) {
                self.view.stdout("[" + fname + "] Function is already complete!\n");
                self.view.flush();
                return false;
            }

            // Haven't completed one or more required helper functions
            else if (self.allowableFnames.indexOf(fname) < 0) { // Function with dependencies
                self.view.stdout("[line " + lineno.toString() + "] Please complete {" + self.dependencies[fname].toString() + "} before attempting {" + fname + "}.\n");
                self.view.flush();
                unHighlight(-1);
                return false;
            }

            // End of function -> need to wraparound 
            else if (lineno > self.endLinenos[fname]) {
                return true;
            }

            // Only accept selection of first line when steps 1-4 are done
            else if ((lineno == self.startLinenos[fname]) &&
                (self.fnameTodos[fname].length == 0) && 
                (self.totalLoopLinenos[fname].length == 0) && 
                (self.needsDegStep[fname].length == 0) && 
                (self.needsSimpl[fname].length == 0)) {


                unHighlight(-1);
                fxnhandler(fname);
                return true;
            }

            // Blank line or comment OR already completed line
            else if (!((lineno) in self.lineMetadata[fname]) || 
                (self.fnameTodos[fname].indexOf(lineno) == -1 && 
                self.totalLoopLinenos[fname].indexOf(lineno) == -1 &&
                self.needsDegStep[fname].indexOf(lineno) == -1 &&
                self.needsSimpl[fname].indexOf(lineno) == -1) ||
                (self.startedMult[fname] && !self.startedDegStep[fname] &&
                self.totalLoopLinenos[fname].indexOf(lineno) == -1) ||
                (self.startedDegStep[fname] && !self.startedSimpl[fname] &&
                self.needsDegStep[fname].indexOf(lineno) == -1)) {

                unHighlight(-1);
                if (self.fnameTodos[fname].length == 0) {
                    if (self.totalLoopLinenos[fname].length != 0) {
                        highlightLoops(fname, -1);
                    }
                    else if (self.needsDegStep[fname].length != 0) {
                        highlightNbrs(fname, -1);
                    }
                    else if (self.needsSimpl[fname].length != 0) {
                        highlightSimpl(fname, -1);
                    }
                    else {
                        highlightFxn(fname, "ltblueline");
                    }
                }
                return false;
            }

            // After finishing individual lines, perform loop multiplication 
            // (or simplification, if there's no more neighbor substitution to be done)
            else if ((self.fnameTodos[fname].length == 0) && 
                ((self.totalLoopLinenos[fname].indexOf(lineno) != -1) /* need to multiply */ ||
                (self.needsDegStep[fname].length == 0 && 
                self.needsSimpl[fname].indexOf(lineno) != -1))) /* need to simplify */ {

                totalloophandler(lineno);
                return true;
            }

            // ...and eventually degree substitution...
            else if ((self.fnameTodos[fname].length == 0) &&
                (self.needsDegStep[fname].length > 0) &&
                ((lineno) in self.loopMetadata[fname]) && 
                (self.needsDegStep[fname].indexOf(lineno) != -1)) /* need to substitute */ {

                nbrhandler(lineno);
                return true;
            }

            unHighlight(-1);

            if (!self.startedMult[fname] && 
                ((self.whileLoops[fname].indexOf(lineno) != -1 && self.halfDone[lineno]) || 
                ((self.whileLoops[fname].indexOf(lineno) == -1) && ((lineno) in self.loopMetadata[fname]) && !self.halfDone[lineno]))) { // loop: expression

                // For the three remaining cases, just highlight a single line
                self.view.addHighlight("blueline", lineno);
                self.activelines.push(lineno);

                // Prompt for the iteration expression's complexity
                var text = step1aText(lineno);
                var dialogClose = self.view.openDialog(text, loopboxhandler2);

                // Append arrow to existing text
                text = self.gutterText[lineno];
                if (text == null) {
                    text = "";
                }
                text = appendArrow(text);
                self.view.setGutter(lineno, text);
                self.view.flush();
                if (!(lineno in self.gutterText)) {
                    self.gutterText[lineno] = "\u2192";
                }
                return true;
            }

            else if ((!(self.startedMult[fname])) && (lineno) in self.loopMetadata[fname] && self.fnameTodos[fname].indexOf(lineno) != -1) { // loop: numiters

                // For the three remaining cases, just highlight a single line
                self.view.addHighlight("blueline", lineno);
                self.activelines.push(lineno);

                //highlightLoop(lineno, "blueline");
                var text = '[Step 1b] Number of iterations of loop beginning in line ' + lineno.toString() + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
                var dialogClose = self.view.openDialog(text, loopboxhandler);

                // Append arrow to existing text
                text = self.gutterText[lineno];
                if (text == null) {
                    text = "";
                }
                text = appendArrow(text);
                self.view.setGutter(lineno, text);
                self.view.flush();
                if (!(lineno in self.gutterText)) {
                    self.gutterText[lineno] = "\u2192";
                }
                return true;
            }

            else if (!((lineno) in self.loopMetadata[fname])) { // single line

                // For the three remaining cases, just highlight a single line
                self.view.addHighlight("blueline", lineno);
                self.activelines.push(lineno);

                var text = '[Step 1] Complexity of line ' + lineno.toString() + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
        	var dialogClose = self.view.openDialog(text, lineboxhandler);

                // Add arrow in gutter pointing to selected line
                text = "";
                text = appendArrow(text);
                self.gutterText[lineno] = "\u2192";
                self.view.setGutter(lineno, text);
                self.view.flush();
                return true;
            }

            return false;
	};
        if (!this.lchandlerSet) {
            this.view.setLineClickHandler(linehandler);
            this.lchandlerSet = true;
        }

        // ~~~~~~~
        // Line click handler for computing the total complexity of a loop
        // ~~~~~~~
        var totalloophandler = function (lineno) {
            var fname = self.activeFname;

            // Need to wrap around
            if (lineno > self.endLinenos[fname] || !((lineno) in self.loopMetadata[fname])) {
                return true;
            }
            unHighlight(-1);

            // Start by prompting for loop multiplication -- if we've already 
            // done all inner loops for this loop!
            if (!(self.loopHalfDone[lineno])) {

                // Highlight all of the (other) available loops in a soft blue
                // and this one in a deeper blue
                unHighlightLoop(lineno, "ltblueline");
                highlightLoops(fname, lineno);
                highlightLoop(lineno, "blueline");

                var text = self.gutterText[lineno];
                text = appendArrow(text);
                self.view.setGutter(lineno, text);
            
                var text = '[Step 2] Multiplied complexity contribution of ';
                if (self.isNested[lineno]) {
                    text = text + "nested ";
                }
                text = text + 'loop beginning in line ' + lineno.toString() + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
                var dialogClose = self.view.openDialog(text, multloopboxhandler);

            }

            // Then move on to deg substitution
            else if (self.needsDegStep[fname].indexOf(lineno) != -1) {
                var text = self.gutterText[lineno];
                text = appendArrow(text);
                self.view.setGutter(lineno, text);
                self.view.flush();
            
                // Highlight all of the (other) available nbr blocks in a soft blue
                // and this one in a deeper blue
                unHighlightLoop(lineno, "ltblueline");
                highlightNbrs(fname, lineno);
                highlightLoop(lineno, "blueline");

                var text = '[Step 3] Eliminate d(i) to get the complexity contribution of ';
                if (self.isNested[lineno]) {
                    text = text + "nested ";
                }
                text = text + 'loop beginning in line ' + lineno.toString() + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
                var dialogClose = self.view.openDialog(text, nbrhandler);
            }

            // Finally, simplification
            else if (self.needsSimpl[fname].indexOf(lineno) != -1) {
                var text = self.gutterText[lineno];
                text = appendArrow(text);
                self.view.setGutter(lineno, text);
            
                // Highlight all of the (other) needing simpl loops in a soft blue
                // and this one in a deeper blue
                unHighlightLoop(lineno, "ltblueline");
                highlightSimpl(fname, lineno);
                highlightLoop(lineno, "blueline");

                var text = '[Step 4] Simplified complexity contribution of ';
                if (self.isNested[lineno]) {
                    text = text + "nested ";
                }
                text = text + 'loop beginning in line ' + lineno.toString() + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
                var dialogClose = self.view.openDialog(text, simploopboxhandler);
            }

            return true;
        }


        // ~~~~~~~ 
        // Line click handler for step 4 (neighbor substitution)
        // ~~~~~~~
        var nbrhandler = function (lineno) {
            var fname = self.activeFname;

            // Need to wrap around
            if (lineno > self.endLinenos[fname]) {
                return true;
            }

            if (self.needsDegStep[fname].indexOf(lineno) < 0) {
                return false;
            }
            unHighlight(-1);

            if (self.needsDegStep[fname].length > 0) {

                // Highlight all of the (other) available needsDegStep in a soft blue
                // and this one in a darker blue
                highlightNbrs(fname, lineno);
                unHighlight(lineno, "ltblueline");

                if ((lineno) in self.loopMetadata[fname]) {
                    unHighlightLoop(lineno, "ltblueline");
                    highlightLoop(lineno, "blueline");
                } 
                else {
                    self.view.addHighlight('blueline', lineno);
                    self.activelines.push(lineno);
                }

                var text = self.gutterText[lineno];
                text = appendArrow(text);
                self.view.setGutter(lineno, text);
                self.view.flush();

                // Prompt for step 4
                text = '[Step 3] Eliminate d(i) to get the true complexity contribution of ';
                if (lineno in self.loopMetadata[fname]) {
                    text = text + 'loop beginning in line ';
                }
                else {
                    text = text + 'line ';
                }
                text = text + lineno.toString() + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
                var dialogClose = self.view.openDialog(text, nbrboxhandler);
            }
            else {
                nextPrompt(fname, lineno);
            }
        }

        // ~~~~~~~ 
        // Line click handler for computing the total complexity of a function
        // ~~~~~~~
        var fxnhandler = function (fname) {
            self.view.flush();
            unHighlightFxn(fname, "ltblueline");
            highlightFxn(fname, "blueline");

            var text = '[Step 5] Total complexity of function ' + fname + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
            var dialogClose = self.view.openDialog(text, fxnboxhandler);
        }

        // --------------------------------------------//
        //             Dialog Box Handlers             //
        // --------------------------------------------//
        // ~~~~~~~
        // Dialog box handler (on enter) for computing per-line complexity
        // ~~~~~~~
	var lineboxhandler = function (text) {
            self.view.flush();
            var lineno = self.activelines[0];
            var fname = self.activeFname;

            // On "empty" enters, move to next line
            if (!text) {
                tryNext(lineno, linehandler, self.fnameTodos);
                return;
            }
            self.progress = true;
            text = text.trim();

            // Check for malformed input
            var bigo = checkInput(text, lineno, linehandler, false);
            if (bigo == "") {
                linehandler(lineno);
                return;
            }

            // Check correctness of submitted answer (bigo) against true 
            // answer (in self.lineMetadata)
            var actual = bigo;
            var expected = self.lineMetadata[fname][lineno].toString();
            actual = simplifyErrCheck(actual, text, linehandler, lineno);
            expected = math.simplify(expected).toString();

            bigo = bigo.replace(/p/g, "d(i)");

            var diff = diffActExp(actual, expected);
            if (diff == 0) { // Correct!
                self.view.stdout("[line " + lineno.toString() + "] Correct!\n");
                self.view.flush();
                text = "\xa0\xa0" + getPad(lineno) + "O(" + formatExpr(bigo) + ")";

                // If this is in a loop whose header has been done, prepend a +
                text = prependPlus(lineno, text);
                self.view.setGutter(lineno, text);
                self.gutterText[lineno] = text;

                // Remove lineno from fnameTodos
                var idx = self.fnameTodos[fname].indexOf(lineno);
                self.fnameTodos[fname].splice(idx, 1);
                
                // Offer next prompt
                nextPrompt(fname, lineno);
            }
            else { // Incorrect!
                if (diff == -99999999) {
                    self.view.stderr("Invalid input \"" + text + "\"; malformed expression.");
                }
                else {
                    self.view.stdout("[line " + lineno.toString() + "] Incorrect answer \"" + text + "\"; please try again.\n");
                } 

                var actualVars = getVars(actual);
                var expectedVars = getVars(expected);

                if (self.hashOps[fname].indexOf(lineno) != -1 && actual.indexOf("1") != -1) {
                    self.view.stderr("Hint: Remember to consider the *worst* case. How many total elements\nmight need to be searched through?");
                    if (self.hasMultipleOps[fname].indexOf(lineno) != -1) {
                        self.view.stderr("Also: There are multiple operations going on in this line. Imagine that\nthis line were broken up into multiple pieces, and think about the\ncomplexity of each piece.");
                    }
                    if (expectedVars.length > 0 && setDifference(actualVars, expectedVars).length > 0) {
                        self.view.stderr("Also: Look at the inputs involved in this line. Now look at the sizes you've\nused in your expression. Are you sure all of those sizes come from inputs\nthat are used in this line?");
                    }
                }
                else if (self.hasMultipleOps[fname].indexOf(lineno) != -1) {
                    self.view.stderr("Hint: There are multiple operations going on in this line. Imagine that\nthis line were broken up into multiple pieces, and think about the\ncomplexity of each piece.");
                     if (expectedVars.length > 0 && setDifference(actualVars, expectedVars).length > 0) {
                         self.view.stderr("Also: Look at the inputs involved in this line. Now look at the sizes you've\nused in your expression. Are you sure all of those sizes come from inputs\nthat are used in this line?");
                     }
                }
                else if ((lineno) in self.callees[fname]) {
                    self.view.stderr("Hint: What are the sizes of the inputs to the callee (" + self.callees[fname][lineno][0] + ")\nin terms of the sizes of the inputs to the caller (" + fname + ")?");
                     if (expectedVars.length > 0 && setDifference(actualVars, expectedVars).length > 0) {
                        self.view.stderr("Also: Look at the inputs involved in this line. Now look at the sizes you've\nused in your expression. Are you sure all of those sizes come from inputs\nthat are used in this line?");
                     }
                }
                else if (expectedVars.length > 0 && setDifference(actualVars, expectedVars).length > 0) {
                    self.view.stderr("Hint: Look at the inputs involved in this line. Now look at the sizes you've\nused in your expression. Are you sure all of those sizes come from inputs\nthat are used in this line?");
                 }

                self.view.flush();
                linehandler(lineno);
            }
	};

        // ~~~~~~~
        // Dialog box handler (on enter) for computing loop numiters
        // ~~~~~~~
        var loopboxhandler = function (text) {
            var lineno = self.activelines[0]; // start of loop
            var fname = self.activeFname;

            // On "empty" enters, move to next line
            if (!text) {
                tryNext(lineno, linehandler, self.fnameTodos);
                return;
            }
            text = text.trim();
            self.progress = true;

            // Check for malformed input; on failure, linehandler will redirect
            // back to loopboxhandler
            var numiters = checkInput(text, lineno, linehandler, false);
            if (numiters == "") {
                linehandler(lineno);
                return;
            }

            // Check correctness of submitted answer (numiters) against true 
            // answer (in self.lineMetadata)
            var actual = numiters;
            var expected = self.lineMetadata[fname][lineno][1].toString();
            actual = simplifyErrCheck(actual, text, linehandler, lineno);
            expected = math.simplify(expected).toString();

            numiters = numiters.replace(/p/g, "d(i)");

            var diff = diffActExp(actual, expected);
            if (diff == 0) { // Correct!
                self.view.stdout("[numiters at " + lineno.toString() + "] Correct!\n");
                self.view.flush();

                // While loops: prompt for individual line complexity of loop header line
                if (self.whileLoops[fname].indexOf(parseInt(lineno, 10)) != -1) {
                    text = "\xa0\xa0" + getPad(lineno) + "(O(" + formatExpr(numiters) + ") * (";
                    text = prependPlus(lineno, text);
                    self.gutterText[lineno] = text;
                    text = appendArrow(text);
                    self.view.setGutter(lineno, text);

                    // Switch to only highlighting the current (loop header) line
                    unHighlight(lineno);
                    self.view.addHighlight('blueline', lineno);
                    self.activelines.push(lineno);

                    var text = step1aText(lineno);
                    var dialogClose = self.view.openDialog(text, loopboxhandler2);
                    self.halfDone[lineno] = true;
                }

                // For loops: done; move on
                else {
                    text = self.gutterText[lineno].split(")");
                    text.splice(text.length - 1, 1);
                    text = text.join(")");
                    text = text + ") + (O(" + formatExpr(numiters) + ") * (";
                    self.gutterText[lineno] = text;
                    self.view.setGutter(lineno, text);

                    // Remove lineno from fnameTodos
                    var idx = self.fnameTodos[fname].indexOf(lineno);
                    self.fnameTodos[fname].splice(idx, 1);
                    prependAllPluses(fname, lineno);

                    // Offer next prompt
                    nextPrompt(fname, lineno);
                }
            }
            else { // Incorrect!
                if (diff == -99999999) {
                    self.view.stderr("Invalid input \"" + text + "\"; malformed expression.");
                }
                else {
                    self.view.stdout("[numiters at " + lineno.toString() + "] Incorrect answer \"" + text + "\"; please try again.\n");

                    var expectedExpr = self.lineMetadata[fname][lineno][0].toString();
                    expectedExpr = math.simplify(expectedExpr).toString();
                    var diffExpr = diffActExp(actual, expectedExpr);
                    if (diffExpr == 0 && self.whileLoops[fname].indexOf(lineno) != -1) {
                        self.view.stderr("Hint: You were asked to first provide the number of iterations; you'll be\nbe prompted for the complexity of the expression to the right of \"while\" next!\n(Think about why this may be; how many times does that expression execute?)");
                    }
                }

                self.view.flush();
                linehandler(lineno);
            }
        }

        // ~~~~~~~
        // Dialog box handler (on enter) for computing loop header complexity
        // ~~~~~~~
        var loopboxhandler2 = function (text) {
            self.view.flush();
            var lineno = self.activelines[0];
            var fname = self.activeFname;

            // On "empty" enters, move to next line
            if (!text) {
                tryNext(lineno, linehandler, self.fnameTodos);
                return;
            }
            text = text.trim();

            // Check for malformed input; on failure, linehandler will redirect
            // back to loopboxhandler1
            var bigo = checkInput(text, lineno, linehandler, false);
            if (bigo == "") {
                linehandler(lineno);
                return;
            }

            // Check correctness of submitted answer (bigo) against true 
            // answer (in self.lineMetadata)
            var actual = bigo;
            var expected = self.lineMetadata[fname][lineno][0].toString();
            actual = simplifyErrCheck(actual, text, linehandler, lineno);
            expected = math.simplify(expected).toString();

            bigo = bigo.replace(/p/g, "d(i)");

            var diff = diffActExp(actual, expected)
            if (diff == 0) { // Correct!
                self.view.stdout("[loop expression at " + lineno.toString() + "] Correct!\n");
                self.view.flush();

                if (self.whileLoops[fname].indexOf(parseInt(lineno, 10)) != -1) {
                    text = self.gutterText[lineno].split("*")[0];
                    text = text + "* (O(" + formatExpr(bigo) + ")";
                    self.gutterText[lineno] = text;
                }
                else {
                    text = "\xa0\xa0" + getPad(lineno) + "O(" + formatExpr(bigo) + ")";
                    text = prependPlus(lineno, text);
                    self.gutterText[lineno] = text;
                    //text = appendArrow(text);
                }
                self.view.setGutter(lineno, text);
                self.view.flush();

                // While loop: totally done (already did numiters)
                if (self.whileLoops[fname].indexOf(parseInt(lineno, 10)) != -1) {
                    // Remove lineno from fnameTodos
                    var idx = self.fnameTodos[fname].indexOf(lineno);
                    self.fnameTodos[fname].splice(idx, 1);
                    prependAllPluses(fname, lineno);

                    // Offer next prompt
                    nextPrompt(fname, lineno);
                }

                // For loop: need to go back for numiters
                else {
                    //unHighlight(-1);
                    //highlightLoop(lineno, "blueline");
                    self.halfDone[lineno] = true;
                    text = appendArrow(text);
                    self.view.setGutter(lineno, text);

                    var text = '[Step 1b] Number of iterations of loop beginning in line ' + lineno.toString() + ': <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
;
                    var dialogClose = self.view.openDialog(text, loopboxhandler);
                }

            }
            else { // Incorrect!
                if (diff == -99999999) {
                    self.view.stderr("Invalid input \"" + text + "\"; malformed expression.");
                }
                else {
                    self.view.stdout("[loop expression at " + lineno.toString() + "] Incorrect answer \"" + text + "\"; please try again.\n");

                    if (self.hashOps[fname].indexOf(lineno) != -1 && actual.indexOf("1") != -1) {
                        self.view.stderr("Hint: Remember to consider the *worst* case. How many total elements\nmight need to be searched through?");
                        if (self.hasMultipleOps[fname].indexOf(lineno) != -1) {
                            self.view.stderr("Also: There are multiple operations going on in this line. Imagine that\nthis line were broken up into multiple pieces, and think about the\ncomplexity of each piece.");
                        }
                    }
                    else if (self.hasMultipleOps[fname].indexOf(lineno) != -1) {
                        self.view.stderr("Hint: There are multiple operations going on in this line. Imagine that\nthis line were broken up into multiple pieces, and think about the\ncomplexity of each piece.");
                    }

                    var expectedNumiters = self.lineMetadata[fname][lineno][1].toString();
                    expectedNumiters = math.simplify(expectedNumiters).toString();
                    var diffNumiters = diffActExp(actual, expectedNumiters);
                    if (diffNumiters == 0 && self.whileLoops[fname].indexOf(lineno) == -1) {
                        self.view.stderr("Hint: You were asked to first provide the complexity for the expression\nto the right of \"in\"; you'll be prompted for the number of iterations next!\n(Think about why this might be; how many times does the expression execute?)");
                    }
                }
                self.view.flush();
                linehandler(lineno);
            }
        }

        // ~~~~~~~
        // Dialog box handler (on enter) for computing the intermediate
        // complexity that a loop contributes to its containing block.
        // ~~~~~~~       
        var multloopboxhandler = function (text) {
            var lineno = self.activelines[0];
            var fname = self.activeFname;

            // On "empty" enters, move to next line
            if (!text) {
                nextPrompt(fname, lineno);
                return;
            }
            text = text.trim();

            // Check for malformed input
            var bigo = checkInput(text, lineno, totalloophandler, true);
            if (bigo == "") {
                totalloophandler(lineno);
                return;
            }
            else if (bigo == "OO") {
                var diff = 1;
            }

            else {
                var actual = bigo;
                var expected = self.lineMetadata[fname][lineno][2].toString();
                //actual = simplifyErrCheck(actual, text, totalloophandler, lineno);
                bigo = bigo.replace(/p/g, "d(i)");
                var diff = diffActExp(actual, expected);
            }

            if (diff == 0) { // Correct!
                var newText = clearLoopUpdateHeader(lineno, bigo, true);
                self.view.setGutter(lineno, newText);
                self.gutterText[lineno] = newText;
                self.loopHalfDone[lineno] = newText;

                finalizeLoop(lineno);
                if (self.outermostLoops[fname].indexOf(lineno) == -1) {
                    self.view.stdout("[multiplication at " + lineno.toString() + "] Correct! No simplification needed until you reach the outermost loop.\n");
                    // Offer next loop prompt
                    nextPrompt(fname, lineno);                    
                }

                else if (self.needsDegStep[fname].indexOf(lineno) == -1) {
                    self.view.stdout("[multiplication at " + lineno.toString() + "] Correct!\n");
                    // Offer next loop prompt
                    nextPrompt(fname, lineno);                    
                }
                else {
                    self.view.stdout("[multiplication at " + lineno.toString() + "] Correct!");
                    nextPrompt(fname, lineno);
                }
                self.view.flush();

            }
            else { // Incorrect!
                if (diff == -99999999) {
                    self.view.stderr("Invalid input \"" + text + "\"; malformed expression.");
                }
                else {
                    self.view.stdout("[multiplication at " + lineno.toString() + "] Incorrect answer \"" + text + "\"; please try again.\n");
                    self.view.flush();

                    if (diff == 1) {
                        var loopTxt = getLoopAnnotations(lineno);
                        self.view.stderr("Hint: you should only include the expression representing the body of the\nloop, specifically: " + loopTxt);
                    }
                    else {
                        var missingTermLines = findMissingTermsMult(actual, expected, lineno);
                        missingTermLines.sort(self.saneSortInt);
                        var duplicateTerms = findDuplicateTerms(actual);

                        if (missingTermLines.length > 0) { 
                            self.view.stderr("Hint: You may have missing or incorrect terms from the following line(s): " + missingTermLines.join(", ") + ".\nRemember that in this intermediate step, you should keep one instance of\neach unique term (even if they are not maximal in the asymptotic case).");
                            if (duplicateTerms.length > 0) {
                                self.view.stderr("Also: You have duplicates of the following terms: " + duplicateTerms.join(", ") + ".\nRemember to include only one instance of each unique term.");
                            }

                            var loopTxt = getLoopAnnotations(lineno);
                            self.view.stderr("One last hint: The expression you're trying to simplify is:\n" + loopTxt);

                        }
                        else if (duplicateTerms.length > 0) {
                            self.view.stderr("Hint: You have duplicates of the following terms: " + duplicateTerms.join(", ") + ".\nRemember to include only one instance of each unique term.");
                        }
                        else {
                            var loopTxt = getLoopAnnotations(lineno);
                            self.view.stderr("Hint: The expression you're trying to simplify is:\n" + loopTxt);
                        }
                    }
                }

                self.view.flush();
                totalloophandler(lineno);
            }
        }              

        // ~~~~~~~
        // Dialog box handler (on enter) for computing the total complexity
        // that a loop contributes to its containing block
        // ~~~~~~~
        var simploopboxhandler = function (text) {
            var lineno = self.activelines[0];
            var fname = self.activeFname;

            // On "empty" enters, move to next line
            if (!text) {
                nextPrompt(fname, lineno);
                return;
            }
            text = text.trim();

            // Check for malformed input
            var bigo = checkInput(text, lineno, totalloophandler, true);
            if (bigo == "") {
                totalloophandler(lineno);
                return;
            }
            else if (bigo == "OO") {
                var diff = 1;
            }

            else {
                var actual = bigo;
                var expected = self.lineMetadata[fname][lineno][4].toString();
                actual = simplifyErrCheck(actual, text, totalloophandler, lineno);
                expected = math.simplify(expected).toString();
                bigo = bigo.replace(/p/g, "d(i)");
                var diff = diffActExp(actual, expected);
            }

            if (diff == 0) { // Correct!
                var newText = clearLoopUpdateHeader(lineno, bigo, false);
                self.view.setGutter(lineno, newText);
                self.gutterText[lineno] = newText;

                // Remove completed lineno from needsSimpl
                finalizeLoop(lineno);
                var idx = self.needsSimpl[fname].indexOf(lineno);
                self.needsSimpl[fname].splice(idx, 1);
                self.view.stdout("[simplification at " + lineno.toString() + "] Correct!\n");
                self.view.flush();

                // Offer next prompt for simplification or, if none is 
                // necessary, final function complexity computation
                nextPrompt(fname, lineno);
            }
            else { // Incorrect!
                if (diff == -99999999) {
                    self.view.stderr("Invalid input \"" + text + "\"; malformed expression.");
                }
                else {
                    self.view.stdout("[simplification at " + lineno.toString() + "] Incorrect answer \"" + text + "\"; please try again.\n");

                    if (diff == 1) {
                        var loopTxt = getLoopAnnotations(lineno);
                        self.view.stderr("Hint: The expression you should simplify is: " + loopTxt);
                    }
                }

                self.view.flush();
                totalloophandler(lineno);
            }
        }

        // ~~~~~~~
        // Dialog box handler (on enter) for neighbor substitution
        // ~~~~~~~
        var nbrboxhandler = function (text) {
            var lineno = self.activelines[0];
            var fname = self.activeFname;

            // On "empty" enters, move to next line
            if (!text) {
                nextPrompt(fname, lineno);
                return;
            }
            text = text.trim();

            // Check for malformed input
            var bigo = checkInput(text, lineno, nbrhandler, true);
            if (bigo == "") {
                nbrhandler(lineno);
                return;
            }

            var actual = bigo;
            var expected = self.lineMetadata[fname][lineno][3].toString();
            actual = simplifyErrCheck(actual, text, nbrhandler, lineno);
            expected = math.simplify(expected).toString();

            var diff = diffActExp(actual, expected);
            if (diff == 0) { // Correct!
                // For for loops, only replace the second half
                if ((lineno) in self.loopMetadata[fname] && self.whileLoops[fname].indexOf(lineno) == -1) {
                    var components = self.gutterText[lineno].split(") + ");
                    var first = components[0] + ") + ";
                }

                // Otherwise, replace the whole thing
                else {
                    var first = "\xa0\xa0";
                }

                var newText = first + "O(" + formatExpr(bigo) + ")";
                self.view.setGutter(lineno, newText);
                self.gutterText[lineno] = newText;

                // Remove completed lineno from needsDegStep
                var idx = self.needsDegStep[fname].indexOf(lineno);
                self.needsDegStep[fname].splice(idx, 1);

                if (self.needsSimpl[fname].indexOf(lineno) != -1) {
                    self.view.stdout("[substitution at " + lineno.toString() + "] Correct!\n");
                }
                else {
                    self.view.stdout("[substitution at " + lineno.toString() + "] Correct!\n");
                }
                self.view.flush();
                        
                // Offer prompt for neighbor simplification or, if none is 
                // necessary, final function complexity computation
                nextPrompt(fname, lineno);
            }
            else { // Incorrect!
                if (diff == -99999999) {
                    self.view.stderr("Invalid input \"" + text + "\"; malformed expression.");
                }
                else {
                    self.view.stdout("[substitution at " + lineno + "] Incorrect answer \"" + text + "\"; please try again.\n");

                    var expectedSimpl = self.lineMetadata[fname][lineno][4].toString();
                    expectedSimpl = math.simplify(expectedSimpl).toString();
                    var diffSimpl = diffActExp(actual, expectedSimpl);
                    if (diffSimpl == 0) {
                        self.view.stderr("Hint: You weren't asked to eliminate any terms yet, but rather to perfom\nsubstitution within existing terms to eliminate d(i).");
                    }
                    else if (lineno in self.edgeProdLines[fname]) {
                        var hintLines = self.edgeProdLines[fname][lineno];
                        self.view.stderr("Hint: Think about how many total times the inner loop beginning in line " + hintLines[1] + "\nexecutes across all iterations of the outer loop beginning in line " + hintLines[0] + ".");
                    }
                    else {
                        self.view.stderr("Hint: What is the maximal value of d(i)?");
                    }
                }
                self.view.flush();
                nbrhandler(lineno);
            }
        }

        // ~~~~~~~
        // Dialog box handler (on enter) for computing the total complexity 
        // of a function
        // ~~~~~~~
	var fxnboxhandler = function (text) {
            var fname = self.activeFname;
            var lineno = self.startLinenos[fname];

            // On "empty" enters, re-prompt
            if (!text) {
                fxnhandler(fname);
                return;
            }
            text = text.trim();

            // Check for malformed input; on failure, linehandler will redirect 
            // back to fxnhandler
            var bigo = checkInput(text, lineno, linehandler, true);
            if (bigo == "") {
                linehandler(lineno);
                return;
            }

            var actual = bigo;
            var expected = self.totalComplexity[fname].toString();
            actual = simplifyErrCheck(actual, text, fxnhandler, fname);
            expected = math.simplify(expected).toString();

            bigo = bigo.replace(/p/g, "d(i)");

            var diff = diffActExp(actual, expected);
            if (diff == 0) { // Correct!
                self.view.stdout("[" + fname + "] Correct!\n");
                var dbgText = "Done with all steps for function " + fname + "!";
                var text = "\xa0\xa0" + getPad(lineno) + "O(" + formatExpr(bigo) + ")";
                self.view.setGutter(lineno, text);
                self.gutterText[lineno] = text;
                self.completedFnames.push(fname);
                self.view.flush();
                
                // Clear active lines
                unHighlight(-1);
                self.activeFname = null;

                // Check to see whether any other fnames are now allowable
                var otherFname = "";
                for (otherFname in self.dependencies) {
                    if (self.allowableFnames.indexOf(otherFname) >= 0) {
                        continue;
                    }

                    var idx = self.dependencies[otherFname].indexOf(fname);
                    if (idx >= 0) {
                        self.dependencies[otherFname].splice(idx, 1);

                        if (self.dependencies[otherFname].length == 0) {
                            // All of this function's dependencies have been
                            // satisfied, so it's now allowable
                            dbgText = dbgText + "\nAll dependencies of " + otherFname + " have been met.";
                            self.allowableFnames.push(otherFname);
                            var lines = self.lineMetadata[otherFname];
                            var linenos = Object.keys(lines);
                            var start = self.startLinenos[otherFname];
                            var end = self.endLinenos[otherFname];
                            self.view.addHighlight('whiteline', start, end);
                        }
                    }
                }
                self.view.stddbg(dbgText);

                // Remove the function we just finished from allowable
                self.allowableFnames.splice(self.allowableFnames.indexOf(fname), 1);
                unHighlightFxn(fname, "blueline");

                if (self.allowableFnames.length > 0) {
                    // Highlight the first line of the next allowable function
                    var minStartLineno = -1;
                    for (i in self.allowableFnames) {
                        otherFname = self.allowableFnames[i];
                        var startLineno = self.startLinenos[otherFname];
                        if (minStartLineno == -1 || minStartLineno > startLineno) {
                            minStartLineno = startLineno;
                        }
                    }
                    tryNext(minStartLineno, linehandler, self.fnameTodos);
                }
                else {
                    // Generate code for submission
                    var code = "";
                    var randNum1 = Math.floor(Math.random() * 89) + 10;
                    var hash = self.view.getCode().hashCode();
                    hash = hash.toString();
                    hash = hash.slice(hash.length - 2);
                    var randNum2 = Math.floor(Math.random() * 8999) + 1000;
                    code = randNum1 + hash + randNum2;
                    self.view.stddbg("Please enter the following code as your solution to the assignment:\n" + code);
                    self.view.flush();
                }
            }
            else { // Incorrect!
                if (diff == -99999999) {
                    self.view.stderr("Invalid input \"" + text + "\"; malformed expression.");
                }
                else {
                    self.view.stdout("[" + fname + "] Incorrect answer \"" + text + "\"; please try again.\n");
                }
                self.view.flush();
                fxnhandler(fname);
            }
            self.view.flush();    
        }

        this.view.flush();
    };

    // ~~~~~~~
    // Helper function for sorting ints in a sane (numeric (non-string)) way
    // ~~~~~~~
    BigO.prototype.saneSortInt = function(a, b) {
        return a - b;
    }

    BigO.prototype.init = function () {
        // All lines have yet to be completed
        for (var fname in this.lineMetadata) {
            var lines = this.lineMetadata[fname];
            var linenos = Object.keys(lines);
            linenos.sort(this.saneSortInt);
            this.fnameTodos[fname] = [];
            for (var i in linenos) {
                this.fnameTodos[fname].push(parseInt(linenos[i], 10));
            }
            // TODO: the following is necessary if there's a docstring, but 
            // wrong otherwise. For now just assuming there will always be a 
            // docstring. Going to strip the docstring in the backend (and
            // shift linenos accordingly) to eliminate the need.
            this.fnameTodos[fname].splice(0, 1);
        }

        // Highlight allowable functions in white
        for (var fname in this.dependencies) {
            var lines = this.lineMetadata[fname];
            var linenos = Object.keys(lines);
            linenos.sort(this.saneSortInt);
            var end = linenos[linenos.length - 1];
            this.endLinenos[fname] = parseInt(end, 10);

            if (this.dependencies[fname].length == 0) {
                this.allowableFnames.push(fname);
                var start = this.startLinenos[fname];
                this.view.addHighlight('whiteline', start, end);
            }
        }
    }

    // Reset the tool - called when Reset button is pushed
    BigO.prototype.reset = async function () {
        var self = this;
        this.clearView = false;
        this.done = false;

        var resetboxhandler = function (text) {
            if (text.trim() == "y" || text.trim() == "Y") {
                self.completedFnames = [];
                self.fnameTodos = {};
                self.allowableFnames = [];

                self.activelines = [];
                self.activeFname = null;

                self.gutterText = {};
                self.loopIndent = {};
                self.loopParent = {};
                self.halfDone = {};
                self.clearView = true;
   
                self.active = false;
            }
            else if (text.trim() != "n" && text.trim() != "N") {
                self.view.stderr("Please type \'y\' for yes or \'n\' for no.");
                self.reset();
            }
            self.done = true;
        }

        if (self.progress) {
            var text = 'Are you sure you want to reset your progress? (y/n) <input type="text" style="width: 10em" class="CodeMirror-search-field"/>';
            var dialogClose = this.view.openDialog(text, resetboxhandler);

            // Wait for result from resetboxhandler
            while (!this.done) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
        else {
            this.clearView = true;
        }

        // Clear view as needed
        if (this.clearView) {
            this.view.clearView();
            this.progress = false;
        }
    };

    // Return constructor object
    return BigO;
})();

module.exports = BigOModel;

