// SVG Icon locations
var icons = {
    "run": "vendor/icons.min.svg#media-play",
    "pause": "vendor/icons.min.svg#media-pause",
    "reset": "vendor/icons.min.svg#media-step-backward",
    "save": "vendor/icons.min.svg#cloud-upload",
    "fresh": "vendor/icons.min.svg#loop-circular",
    "download": "vendor/icons.min.svg#cloud-download",
    "load": "vendor/icons.min.svg#folder",
    "join": "vendor/icons.min.svg#people",
    "leave": "vendor/icons.min.svg#person",
    "docs": "vendor/icons.min.svg#list-rich",
    "demos": "vendor/icons.min.svg#star",
    "about": "vendor/icons.min.svg#info",
    "expand": "vendor/icons.min.svg#fullscreen-enter",
    "shrink": "vendor/icons.min.svg#fullscreen-exit",
    "minimize": "vendor/icons.min.svg#minus",
    "close": "vendor/icons.min.svg#x"
};
    
module.exports = icons;
