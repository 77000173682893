// Modified from CodeMirror foldcode.js

// CodeMirror
const CodeMirror = require('../vendor/CodeMirror/lib/codemirror.js');

// Find Python block, ignore blanks and comments
const pythonRangeFinder = function(cm, start) {
    var tabSize = cm.getOption("tabSize");
    var firstLine = cm.getLine(start.line);
    var myIndent = CodeMirror.countColumn(firstLine, null, tabSize);
    var last = null;

    for (var i = start.line + 1, end = cm.lineCount(); i < end; ++i) {
        var curLine = cm.getLine(i);
        if (!/^\s*(?:\#.*)?$/.test(curLine)) {
            if (CodeMirror.countColumn(curLine, null, tabSize) <= myIndent) {
                break;        
            };
            last = i;
        };
    };
    
    if (!last) {
        // No foldable block
        return null;
    };
    
    // Return foldable region
    return {
        from: {line: start.line, ch: firstLine.length},
        to: {line: last, ch: cm.getLine(last).length}
    };
};

// Create new fold function that uses a marker and widget
const newFoldFunction = function(rangeFinder, widget) {
  if (widget == null) widget = "\u2194";
  if (typeof widget == "string") {
    var text = document.createTextNode(widget);
    widget = document.createElement("span");
    widget.appendChild(text);
    widget.className = "CodeMirror-foldmarker";
  }

  return function(cm, pos) {
    if (typeof pos == "number") pos = {line: pos, ch: 0};
    var range = rangeFinder(cm, pos);
    if (!range) return;

    var present = cm.findMarksAt(range.from), cleared = 0;
    for (var i = 0; i < present.length; ++i) {
      if (present[i].__isFold) {
        ++cleared;
        present[i].clear();
      }
    }
    if (cleared) {
        // Clear gutter marker
        cm.setGutterMarker(pos.line, "fold-gutter", null);        
        return;
    }

    var myWidget = widget.cloneNode(true);
    var myRange = cm.markText(range.from, range.to, {
      replacedWith: myWidget,
      clearOnEnter: true,
      __isFold: true
    });

    // Clear widget and marker when unfolded
    CodeMirror.on(myWidget, "mousedown", function() {
        myRange.clear();
    });
    CodeMirror.on(myRange, "clear", function () {
        // Clear gutter marker
        cm.setGutterMarker(pos.line, "fold-gutter", null);
    });

    // Add gutter marker
    var foldMark = document.createElement("span");
    foldMark.appendChild(document.createTextNode("\u25b6"));
    foldMark.style.color = "#600";
    cm.setGutterMarker(pos.line, "fold-gutter", foldMark);
  };
};

module.exports = {
    pythonRangeFinder: pythonRangeFinder,
    newFoldFunction: newFoldFunction
};
