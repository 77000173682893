/**
 * Adapted from Google's TicTacToe example:
 * https://cloud.google.com/solutions/using-firebase-real-time-events-app-engine
 * https://github.com/GoogleCloudPlatform/python-docs-samples/tree/master/appengine/standard/firebase/firetactoe
 * 
 * @author Stephen Wong 
 * @date 6/21/17 
 */

'use strict';

/**
 * Initialize the Firebase communications.   Note that there is a 100 ms delay before Firebase actually starts.
 * 
 * @param channelId  The channel ID (client ID) that this browser client is using.
 * @param token The JWS authentication token to use with Firebase
 * @param onMessage The message handler for the data value retrieved from Firebase
 * @param critricalErrorFn  A function of one string parameter to handle critical errors.
 * @returns  A function of no parameters that the system should use to close the Firebase connection and delete any resources when the session is over.
 */
function initFirebase(channelId, token, onMessage, criticalErrorFn) {

	/**
	 * This method lets the server know that the user has opened the channel
	 * After this method is called, the server may begin to send updates
	 */
	function onOpened() {
		console.log("Firebase: posting back that channel has opened.")
		$.post('/firebase/open', {channel_id:channelId});
	}
	
	
	
	/**
	 * The function that deletes the data associated with the Firebase path
	 * it is critical that this data be deleted since it costs money.
	 * This function is set when the channel is opened.
	 */
	var deleteChannel = function () {
		console.log("Firebase: Function to close the channel not yet defined!")
	}
	
	function closeFirebaseChannel() {
		console.log("Firebase: Attempting to close Firebase channel.")
		deleteChannel();
		// Only delete the channel once, so reset it back to a no-op.  Re-opening the channel will make this operational again.
		deleteChannel = function () {
			console.log("Firebase: Channel was already closed.");
		}
	}
	
	/**
	 * This function opens a realtime communication channel with Firebase
	 * It logs in securely using the client token passed from the server
	 * then it sets up a listener on the proper database path (also passed by server)
	 * finally, it calls onOpened() to let the server know it is ready to receive messages
	 */
	function openChannel() {
		console.log("Firebase: opening channel...")
	
		// sign into Firebase with the token passed from the server
		firebase.auth().signInWithCustomToken(token).catch(function(error) {
			console.log('Firebase login Failed!', error.code);
			console.log('Firebase error message: ', error.message);
			criticalErrorFn("Error while initializing the Firebase connection: ("+error.code+") "+error.message+".   Please close this page and reload it.");
		});

		// Set the function to delete the channel
		deleteChannel = function () {
			console.log("Firebase: posting back to close the channel.")
			$.post('/firebase/close', {channel_id:channelId});
			
		}

		// setup a database reference at path /channels/channelId
		var channel = firebase.database().ref('channels/' + channelId);
		// add a listener to the path that fires any time the value of the data changes
		channel.on('value', function(data) {
//			console.log("Firebase value handler: Received data = "+JSON.stringify(data))
			if(null != data && null != data.val()) {
				onMessage(data.val());
			}
			else {
				console.log("Firebase value handler: Received data was null!")
			}
		});

		onOpened();  // let the user and the server know that the channel is open
	
	}

	/**
	 * This function opens a communication channel with the server
	 */
	function initialize() {
		openChannel(token);
	}
	
	setTimeout(initialize, 100);  // Waits 100 ms before attempting to initialize the Firebase channel.  Is this really necessary?
	
	return closeFirebaseChannel
}

/**
 * Initialize the Firebase communications and message handling.
 * 
 * Call this from the body's onload event handler, otherwise the page's elements won't be ready yet!
 * 
 * @param channelId  Firebase channel ID string.  If null/empty, this function does nothing.   
 * @param token Firebase JWT authentication token string 
 * @param tabManager  tab manager function reference
 * @param errorMsgHandler function of one string input parameter to handle error messages.
 * @param onOpenFn No parameter function that is called right after Firebase is successfully initialized
 * @param criticalErrorFn A function of one string input parameter to handle critical Firebase errors
 * @returns  Nothing
 */
function initOwltestFirebase(channelId, token, tabManager, errorMsgHandler, onOpenFn, criticalErrorFn) {
	if(! channelId) {
		console.log("Firebase initOwlTestFirebase(): Empty channel ID supplied, no initialization performed.  This may be normal.");
		return;
	}
	var closeFirebaseFn = null;
	
	/**
	 * Assumes that msgObj is non-null!
	 */
	function onMessage(msgObj) {
		console.log("Firebase onMessage(): msg_obj = "+JSON.stringify(msgObj));
		if (null != msgObj.error_msg) {
			errorMsgHandler( msgObj.error_msg);
		}

		if (null == msgObj.tabs) {   // If a dictionary value is empty, Firebase sends a null, not an empty dictionary!
			msgObj.tabs = {};
		}
		tabManager.makeTabs(msgObj);
		tabManager.showTabs(0);
		

		if(null != closeFirebaseFn) {
			closeFirebaseFn();
			console.log("Firebase onMessage(): message received and processed.  Server notified to close Firebase channel.");
		}
		else {
			console.log("Firebase onMessage(): message received and processed but closeFirebaseFn was null so server was NOT notified!");
		}
	}

	closeFirebaseFn = initFirebase(channelId, token, onMessage, criticalErrorFn)
	if(null == closeFirebaseFn) {
		var errorMsg = "Firebase initOwltestFirebase(): The returned closeFirebaseFn was null so the server cannot be notified when a message has been processed!";
		console.log(errorMSg);
		criticalErrorFn(errorMsg);
	}
	else {
		console.log("Firebase initOwltestFirebase(): Sucessfully initialized!");
		onOpenFn();
	}
	
	return closeFirebaseFn;
}

module.exports = {
    initOwltestFirebase: initOwltestFirebase
};
