// CSS for view
require('../vendor/CodeMirror/lib/codemirror.css');
require('../vendor/CodeMirror/addon/dialog/dialog.css');
require('../vendor/CodeMirror/addon/hint/show-hint.css');
require('../styles/codeskulptor.css');

// Icon locations
const icons = require('./svgicons.js');

// CodeMirror
const CodeMirror = require('../vendor/CodeMirror/lib/codemirror.js');
require('../vendor/CodeMirror/mode/python/python.js');
require('../vendor/CodeMirror/addon/dialog/dialog.js');
require('../vendor/CodeMirror/addon/edit/matchbrackets.js');
require('../vendor/CodeMirror/addon/search/search.js');
require('../vendor/CodeMirror/addon/search/searchcursor.js');

// Modify CodeMirror's folder
require('./python-fold.js');
const fold = require('./fold.js');

// Set up View constructor
const CSView = (function ($) {
    // Global reference to this view
    var thisView;

    // Minimum sizes for CodeSkulptor GUI
    var minBodyHeight = 560;
    var minBodyWidth = 560;
    var minOutputWidth = 100;
    var minActiveHeight = 353;
    var bottomGutter = 50;

    // Minimum distance of frame from edge of window
    var minFrameOffset = 40;

    // true if need scroll bars for height/width
    var doScrollHeight = false;
    var doScrollWidth = false;

    // true if something is running
    var isRunning = false;

    // Very stupid browser detection
    var ua = (navigator && navigator.userAgent) ? navigator.userAgent : "";
    var isChrome = RegExp('\\bChrome\\b', 'i').exec(ua) ? true : false;
    var isFirefox = (!isChrome && RegExp('\\b(?:Firefox|Minefield)\\b', 'i').exec(ua)) ? true : false;
    var isSafari = (!isChrome && !isFirefox && RegExp('\\bSafari\\b', 'i').exec(ua)) ? true : false;

    // Constructor
    var View = function () {
        // Set reference to this view
        var view = this;
        thisView = this;

        // Python folder
        // this.folder = fold.newFoldFunction(fold.pythonRangeFinder);

        // Python comment/uncomment selection
        //   Need to check for hidden lines!
        var comment = function (cm) {
            var from = cm.getCursor(true);
            var to = cm.getCursor(false);

            var fromline = from.line;
            var toline = to.line;

            if (to.ch === 0) {
                // Selection ends at start of line, don't comment that line
                toline -= 1;
            } else {
                // End of selection is going to shift right because of
                // inserted #
                to.ch += 1;
            }

            cm.operation(function () {
                for (var i = fromline; i <= toline; i++) {
                    cm.replaceRange("#", {line: i, ch: 0});
                }

                // Restore selection
                cm.setSelection(from, to);
            });
        };

        var uncomment = function (cm) {
            var from = cm.getCursor(true);
            var to = cm.getCursor(false);

            var fromline = from.line;
            var toline = to.line;

            if (to.ch === 0) {
                // Selection ends at start of line, don't uncomment that line
                toline -= 1;
            }

            var creg = /^(\s*)\#/;
            cm.operation(function () {
                var line, newline;
                for (var i = fromline; i <= toline; i++) {
                    line = cm.getLine(i);
                    newline = line.replace(creg, "$1");
                    if (line !== newline) {
                        cm.replaceRange(newline, {line: i, ch: 0}, {line: i, ch: line.length});
                    }
                }
                if ((line !== newline) && (to.ch !== 0)) {
                    // # was removed from last line, move selection end
                    to.ch -= 1;
                }

                // Restore selection
                cm.setSelection(from, to);
            });
        };

        // Actual editor
        this.pyversion = 2;
        this.tabStop = 4;
        this.editor = CodeMirror.fromTextArea(
            $("#code")[0],
            {
                mode: {name: "python",
                       version: this.pyversion,
                       singleLineStringErrors: false},
		gutters: ["bigo-gutter"],
                fixedGutter: false,
                lineNumbers: true,
                indentUnit: this.tabStop,
                tabMode: "indent",
                matchBrackets: true,
		readOnly: true,
                extraKeys: {// "Ctrl-Q": function(cm) { view.folder(cm, cm.getCursor().line); },
                            "Ctrl-K": comment,
                            "Shift-Ctrl-K": uncomment}
            });

        // Fold on gutter click
        // this.editor.on("gutterClick", this.folder);

        // Buffer for console output
        this.buffer = [];

        // "pre" element for output into console
        this.pre = null;

        // Create variables for page elements
        var elements = {
            // Skulpt Console (contained within outputPanel)
            "outputPanel":     "#outputPanel",
            "console":         "#console",

            // Split bar
            "splitBar":        "#splitbar",
            "grip":            "#grip",

            // Buttons
            "runButton":       ".runBtn",
            "pauseButton":     ".pauseBtn",
            "nextButton":      ".nextBtn",
            "stopButton":      ".stopBtn",
            "saveButton":      ".saveBtn",
            "dlButton":        ".dlBtn",
            "freshButton":     ".freshBtn",
            "loadLocalButton": ".loadLocalBtn",
            "collabButton":    ".collabBtn",
            "resetButton":     ".resetBtn",
            "docsButton":      ".docsBtn",
            "demosButton":     ".demosBtn",
            "aboutButton":     ".aboutBtn",

            // File input
            "localFile":       "#localfile",

            // Download link
            "dlanchor":        "#dlhref",

            // Topbar
            "topbar":          "#controls",
            "brand":           "#brand",

            // Row
            "outputRow":       '#outputRow',

            // Toolbars
            "portraitBar":     '#btnToolbarPortrait',
            "landscapeBar":    '#btnToolbarLandscape',

            // Active area for console/output
            "activeArea":      "#active",

            // Containers
            "codeContainer":   "#codeContainer",
            "outputContainer": "#outputContainer",

            // Body
            "body":            "body",

            // Collaboration
            "collabSession":   "#collabSession",
            "collabIcon":      ".collabIcon",
            "collabLabel":     ".collabLabel",

            // Run/Pause/Stop
            "runIcon":         ".runIcon",
            "runLabel":        ".runLabel"
        };

        // Set up jQuery selectors
        for (var el in elements) {
            this[el] = $(elements[el]);
        }

        // Allow Ctrl-S in editor to save
        // CodeMirror.commands.save = function (cm) {
        //     view.saveButton.click();
        // };

        // Set up portrait/landscape switching
        var setButtonDisplay = function () {
            // Decrease output width by:
            //  Padding on active area: 30
            //  Slop: 5
            var outputWidth = view.outputRow.width() - 35;

            if (window.innerWidth < window.innerHeight || window.innerWidth < 650) {
                view.landscapeBar.hide();
                view.portraitBar.show();

                view.activeArea.width(outputWidth);
            } else {
                view.landscapeBar.show();
                view.portraitBar.hide();

                view.activeArea.width(outputWidth - view.landscapeBar.outerWidth(true));
            }
        };

        setButtonDisplay();

        $(window).resize(setButtonDisplay);

        if(!('ontouchstart' in window)) {
            $('[data-toggle="tooltip"]').tooltip({delay: { "show": 500, "hide": 100 }});
        }
    };

    // Seems to be necessary for cross-browser event triggering
    // Fire "event" on "element".
    var fireEvent = function(element, event) {
        var evt;

        if (element[event]) {
            return element[event]();
        }

        if (document.createEvent) {
            // dispatch for firefox + others
            evt = document.createEvent("HTMLEvents");
            evt.initEvent(event, true, true ); // event type,bubbling,cancelable
            return !element.dispatchEvent(evt);
        } else {
            // dispatch for IE
            evt = document.createEventObject();
            return element.fireEvent('on'+event,evt);
        }
    };

    // Configure view without starting it
    //
    //  model - adapter to model
    View.prototype.configure = function (model) {
        this.model = model;
    };

    // Adjust editor/console widths
    //
    //  view     - view object
    //  splitBar - desired pixel location of the split bar
    var resizeWidth = function (view, splitBar) {
        // Width of editor and output areas
        view.width = view.activeArea.width();

        // Determine widths of code and output
        var leftOffset = view.codeContainer.position().left;
        var codeWidth = splitBar - leftOffset;
        var outputWidth = view.width - codeWidth;

        if (outputWidth < minOutputWidth) {
            outputWidth = minOutputWidth;
            codeWidth = view.width - outputWidth;
        } else if (codeWidth < minOutputWidth) {
            codeWidth = minOutputWidth;
            outputWidth = view.width - codeWidth;
        }

        view.codeContainer.width(codeWidth);
        view.outputContainer.width(outputWidth);

        // Add/remove scroll bars, if necessary
        var bodyWidth = view.body.width();

        if ((bodyWidth < minBodyWidth) && (doScrollWidth === false)) {
            view.body.css({"overflow-x": "auto"});
            doScrollWidth = true;
        } else if ((bodyWidth >= minBodyWidth) && (doScrollWidth === true)) {
            view.body.css({"overflow-x": "hidden"});
            doScrollWidth = false;
        }
    };

    // Adjust editor/console height
    //
    //  view    - view object
    //  height  - window height
    var resizeHeight = function (view, height) {
        // Adjust for top bar and bottom gutter and maintain minimum size
        var top = $('.row-no-gutter').offset().top;
        var headerHeight = $('#codeContainer').find('.panel-heading').outerHeight(true);
        var offset = top + headerHeight + bottomGutter;
        var consolePadding = $('#outputPanel').outerHeight() - $('#outputPanel').height();

        consoleHeight = ((height - offset) >= minActiveHeight) ? (height - offset) : minActiveHeight;

        // Resize editor
        view.editor.setSize(null, consoleHeight + consolePadding);
        if (view.usingFirepad) {
            view.firepad.setSize(null, consoleHeight + consolePadding);
        }

        // Resize console
        view.outputPanel.height(consoleHeight);

        view.editor.refresh();

        // Add/remove scroll bars, if necessary
        var bodyHeight = view.body.height();

        if (bodyHeight < minBodyHeight && doScrollHeight === false) {
            view.body.css({"overflow-y": "auto"});
            doScrollHeight = true;
        } else if (bodyHeight >= minBodyHeight && doScrollHeight === true) {
            view.body.css({"overflow-y": "hidden"});
            doScrollHeight = false;
        }
    };

    // Return true if "child" is a descendent of "parent", false otherwise
    var isDescendant = function (parent, child) {
        var node = child.parentNode;
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    };

    var setupTooltip = function (button) {
        button.tooltip();
        button.mouseleave(function () {
            button.tooltip('hide');
        });
    };

    // Actually start view
    View.prototype.start = function () {
        var model = this.model;
        var view = this;
        var active = document.getElementById("active");

        // Set Button Actions
        setupTooltip(this.runButton);
        this.runButton.click(function () {
            // Remove focus for FF bug
            view.runButton.blur();

            // Prevent editing while a program is running
            // view.editor.options.readOnly = true;
            $('.CodeMirror, #codePanelBackground').css('background-color', '#d6d6d6');

            // Toggle between running and paused
            if (isRunning) {
                if (model.isBlocked()) {
                    // Do not allow pausing during a blocking operation
                    return;
                }
                model.reset();
            } else {
                view.runIcon.attr("xlink:href", icons["reset"]);
                view.runLabel.html("Reset");
                view.runButton.attr("data-original-title", "Stop the analysis and clear all input/output.");
                isRunning = true;
                model.run();
            }
        });

        // setupTooltip(this.resetButton);
        // this.resetButton.click(function () {
        //     // Remove focus for FF bug
        //     view.resetButton.blur();
        //     model.reset();
	// });

        // setupTooltip(this.saveButton);
        // this.saveButton.click(function () {
        //     // Remove focus for FF bug
        //     view.saveButton.blur();

        //     model.save();
        // });

        // Determine mouse position in window from event "evt"
        var mousePos = function(evt) {
            var curr = active;
            var top = 0;
            var left = 0;

            var clientX, clientY;

            if (evt.clientX !== undefined && evt.clientY !== undefined)
            {
                clientX = evt.clientX;
                clientY = evt.clientY;
            }
            else if (evt instanceof TouchEvent &&
                     evt.changedTouches !== undefined &&
                     evt.changedTouches[0] !== undefined)
            {
                clientX = evt.changedTouches[0].clientX;
                clientY = evt.changedTouches[0].clientY;
            }

            while (curr && curr.tagName != 'BODY') {
                top  += curr.offsetTop;
                left += curr.offsetLeft;
                curr = curr.offsetParent;
            }

            // Return relative mouse position
            return {
                x: clientX - left + window.pageXOffset,
                y: clientY - top + window.pageYOffset
            };

            // Should this be used instead?
            // return {
            //     x: evt.pageX - left,
            //     y: evt.pageY - top
            // };
        };

        // Mouse move handler, slide grip
        var mmove = function (evt) {
            var pos = mousePos(evt);
            var width = pos.x >= view.width ? view.width : pos.x;
            resizeWidth(view, width);
            view.ratio = view.codeContainer.width() / view.activeArea.width();
            view.split = true;
        };

        // Mouse up handler, stop listening to mouse events
        var mdone = function (evt) {
            // Mouse events
            active.removeEventListener("mousemove", mmove, true);
            active.removeEventListener("mouseup", mdone, true);
            active.removeEventListener("mouseout", mout, true);

            // Touch events
            active.removeEventListener("touchmove", mmove, true);
            active.removeEventListener("touchend", mdone, true);
        };

        // Mouse out handler, stop listening to mouse events if
        // leaving the main active area
        var mout = function (evt) {
            var to = evt.toElement;
            if ((to !== active) && !isDescendant(active, to)) {
                active.removeEventListener("mousemove", mmove, true);
                active.removeEventListener("mouseup", mdone, true);
                active.removeEventListener("mouseout", mout, true);
            }
        };

        // Change the cursor when over the split bar
        this.splitBar.hover(function () {
            $(this).css('cursor', 'col-resize');
        }, function () {
            $(this).css('cursor', 'auto');
        });

        // Allow split bar to be dragged
        this.splitBar.mousedown(function () {
            // cancel any text selections
            document.body.focus();
            active.addEventListener("mousemove", mmove, true);
            active.addEventListener("mouseup", mdone, true);
            active.addEventListener("mouseout", mout, true);
            return false;
        });

        this.splitBar.bind("touchstart", function () {
            // cancel any text selections
            document.body.focus();
            active.addEventListener("touchmove", mmove, true);
            active.addEventListener("touchend", mdone, true);
            return false;
        });

        // Maximize editor when split bar is double clicked
        this.splitBar.dblclick(function () {
            if (view.split) {
                resizeWidth(view, view.width);
                view.split = false;
                view.savedRatio = view.ratio;
                view.ratio = 1.0;
            } else {
                view.split = true;
                view.ratio = view.savedRatio;
                resizeWidth(view, view.width * view.ratio + view.codeContainer.position().left);
            }
        });

        // Resize editor/console when window is resized
        window.onresize = function (evt) {
            var h = $(window).height();
            resizeHeight(view, h);
            resizeWidth(view, view.activeArea.width() * view.ratio + view.codeContainer.position().left);
            $('#splitbar').height($('#codeContainer .cs-panel').height());
        };

        // Resize immediately to current window
        view.width = view.activeArea.width();
        view.ratio = view.codeContainer.width() / view.activeArea.width();
	//view.ratio = 0.6;  // Give code container more width
        view.split = true;
        $(window).trigger('resize');

        // Set up hash change action and fire it to detect initial URL
        $(window).bind("hashchange", function () {
            model.loadRemote(window.location.hash);
        });

        this.model.loadRemote(window.location.hash);
    };

    // Update URL hash
    View.prototype.setFilename = function (filename) {
        window.location.hash = filename;
    };

    // Get the current state of the editor, should be opaque to model
    View.prototype.getEditState = function () {
        var scrollInfo = this.editor.getScrollInfo();
        var cursorPos = this.editor.getCursor();
        var foldedLines = [];
        var inFold = false;

        // Find all folded blocks
        for (var i = 0, end = this.editor.lineCount(); i < end; ++i) {
            var present = this.editor.findMarksAt({line: i, ch: 0});
            var marked = false;
            for (var j = 0; j < present.length; ++j) {
                if (present[j].__isFold) {
                    marked = true;
                    if (!inFold) {
                        foldedLines.push(i-1);
                        inFold = true;
                    }
                }
            }
            if (inFold && !marked) {
                inFold = false;
            }
        }

        var editState = {
            x: scrollInfo.left,
            y: scrollInfo.top,
            cursor: cursorPos,
            folded: foldedLines
        };

        return editState;
    };

    var restoreEditState = function (cm, editState, folder) {
        if (editState) {
            cm.setCursor(editState.cursor);
            cm.scrollTo(editState.x, editState.y);
            for (var i=0; i<editState.folded.length; i++) {
                folder(cm, editState.folded[i]);
            }
        }
    };

    // Set the code in the editor
    //
    //  code      - string to place in editor
    //  editstate - optional state (from getEditState) to restore editor state
    View.prototype.setCode = function (code, editState) {
        var cm = this.editor;
        var folder = this.folder;
        var fp = this.firepad;

        if (this.usingFirepad) {
            this.firepad.setCode(code, function () {
                restoreEditState(cm, editState, folder);
            });
        } else {
            cm.operation(function () {
                cm.setValue(code);
                restoreEditState(cm, editState, folder);
            });
        }
    };

    // Returns the current code in the editor
    View.prototype.getCode = function () {
        return this.editor.getValue();
    };

    // Returns the current tab stop
    View.prototype.getTabStop = function () {
        return this.tabStop;
    };

    var getSpaces = function (whitespace, tabStop) {
        var spaces = "";
        var idx;
        var nextTabStop;

        for (idx = 0; idx < whitespace.length; idx++) {
            if (whitespace.charAt(idx) === "\t") {
                // Compute space to next tab stop
                nextTabStop = tabStop - (spaces.length % tabStop);
                // Add nextTabStop spaces
                spaces += Array(nextTabStop+1).join(" ");
            } else {
                spaces += " ";
            }
        }

        return spaces;
    };

    // Replace all tabs in leading indents with spaces
    View.prototype.replaceLeadingTabs = function () {
        var spaceRE = /^ *\t[ \t]*/gm;
        var cm = this.editor;
        var code = this.editor.getValue();
        var matches = [];
        var match;
        var idx;
        var spaces;
        var first, last;

        // Find all leading indentation that includes tabs
        while ((match = spaceRE.exec(code)) != null) {
            matches.push([match[0], match.index, spaceRE.lastIndex]);
        }

        // Replace all tab-based leading indentation with spaces
        for (idx=matches.length-1; idx>=0; idx--) {
            spaces = getSpaces(matches[idx][0], this.tabStop);
            first = cm.posFromIndex(matches[idx][1]);
            last = cm.posFromIndex(matches[idx][2]);
            cm.replaceRange(spaces, first, last);
        }
    };

    // Set the URL hash
    View.prototype.setHash = function(hash) {
        window.location.hash = hash;
    };

    // Force all current code into form textarea
    View.prototype.prepareSubmit = function () {
        this.editor.save();
    };

    // Output "text" to the console
    View.prototype.consoleOutput = function (text) {
        // Buffer text for later printing
        this.buffer.push(text);
    };

    // Flush buffer to console
    View.prototype.consoleFlush = function () {
        if (this.buffer.length === 0) {
            // Nothing to flush
            return;
        }

        if (!this.pre) {
            // Make new <pre>
            this.pre = $("<pre />");
            this.console.append(this.pre);
        }

        // Append text and scroll console
        //  escape the text first to deal with HTML characters
        var text = this.buffer.join('');
        var escapedText = $('<div />').text(text).html();
        this.pre.append(escapedText);
        this.outputPanel.scrollTop(this.outputPanel.prop("scrollHeight"));
        this.buffer = [];
    };

    // Output "text" to the console in "color"
    View.prototype.colorOutput = function (text, color) {
        // Flush regular output
        this.consoleFlush();

        // Place a colored <span> within a <pre>
        var pre = $("<pre />");
        var span = $("<span />");
        span.css("color", color);

        // Place text in span
        //  escape the text first to deal with HTML characters
        var escapedText = $('<div />').text(text).html();
        span.append(escapedText);

        // Update console
        pre.append(span);
        this.console.append(pre);
        this.outputPanel.scrollTop(this.outputPanel.prop("scrollHeight"));

        // Normal output <pre> is closed
        this.pre = null;
    };

    // Highlight lines
    View.prototype.addHighlight = function (highlightClass, lineStart, lineEnd) {
	var idx;
	lineEnd = lineEnd || lineStart;
	for (idx = lineStart; idx <= lineEnd; idx++) {
	    this.editor.addLineClass(idx - 1, "background", highlightClass);
	}
	this.editor.setCursor(lineStart - 1);
	this.editor.focus();
    };

    View.prototype.removeHighlight = function (highlightClass, lineStart, lineEnd) {
	var idx;
	if (lineStart) {
	    lineEnd = lineEnd || lineStart;
	}
	lineStart = lineStart || 1;
	lineEnd = lineEnd || this.editor.lineCount();
	for (idx = lineStart; idx <= lineEnd; idx++) {
	    this.editor.removeLineClass(idx - 1, "background", highlightClass);
	}
    };

    View.prototype.resetControlButtons = function () {
        // Allow editing once again
        // this.editor.options.readOnly = false;
        $('.CodeMirror, #codePanelBackground').css('background-color', 'white');

        this.runIcon.attr("xlink:href", icons["run"]);
        this.runLabel.html("Start");
        this.runButton.attr("data-original-title", "Begin analyzing the complexity of the code.");

        $('.dropdown').hide();
        $('.pythonFrame').remove();
        $('.guiFrameWrapper').remove();

        isRunning = false;
        zIndex = 5000;
        offset = 75;

        this.removeHighlight();
        this.activeLine = null;
    };

    View.prototype.lineHandler = function (handler) {
	var click = function (cm, line, gutter, event) {
	    handler(line + 1);
	};
	this.editor.on('gutterClick', click);
    };

    View.prototype.dialog = function (template, handler) {
	return this.editor.openDialog(template, handler);
    };

    View.prototype.setGutter = function (lineNo, text) {
	var marker = null;

	if (text !== null) {
	    marker = document.createElement("SPAN");
	    $(marker).text(text);
	}

        this.editor.setGutterMarker(lineNo - 1, "bigo-gutter", marker);
    };

    // Reset view
    View.prototype.reset = function () {
        this.removeHighlight();

        // Clear console
        this.console.html("");
        this.pre = null;

        // Clear search results
        if (CodeMirror.commands.clearSearch !== undefined) {
            CodeMirror.commands.clearSearch(this.editor);
        }

	// Clear gutter
	this.editor.clearGutter('bigo-gutter');
    };

    View.prototype.clearView = function () {
        this.runIcon.attr("xlink:href", icons["run"]);
        this.runLabel.html("Start");
        this.runButton.attr("data-original-title", "Begin analyzing the complexity of the code.");
        isRunning = false;

        this.resetControlButtons();
        this.consoleFlush();
        this.reset();
    };

    // Return Constructor object
    return View;
})(jQuery, window);

module.exports = CSView;
