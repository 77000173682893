// Google Storage configuration
const gsconf = require('csgs');

// CodeMirror
const CodeMirror = require('../vendor/CodeMirror/lib/codemirror.js');

// Controller
var controller = require('./controller.js');

// String formatting.  Replaces {n} in string with nth argument.
String.prototype.format = function() {
  var args = arguments;
  return this.replace(/{(\d+)}/g, function(match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
};

$(function () {
    // Use subdomain as key to pick correct csconf and gsconf?

    // var location = window.location;
    // var protocol = location.protocol;
    // var domain = location.hostname;
    // var subdomain = domain.split('.')[0];

    window.CodeSkulptor = {};
    window.CodeSkulptor.controller = new controller(gsconf);
    window.CodeSkulptor.codemirror = CodeMirror.version;
});
